import Phaser from 'phaser';

import configs from '../configs/configs';
import Background from '../components/common/Background';
import PopupWelcomeNoWar from '../components/popup/PopupWelcomeNoWar';
import PopupWelcomeEndGame from '../components/popup/PopupWelcomeEndGame';
import PopupWelcomeWar from '../components/popup/PopupWelcomeWar';
import Header from '../components/action-buttons/Header';
import InfoButtons from '../components/action-buttons/InfoButtons';
import GangsterHouse from '../components/common/GangsterHouse';
import Footer from '../components/action-buttons/Footer';
import PopupBuy from '../components/popup/PopupBuy';
import PopupWar from '../components/popup/PopupWar';
import PopupSettings from '../components/popup/PopupSettings';
import PopupSwap from '../components/popup/PopupSwap';
import PopupWarHistory from '../components/popup/PopupWarHistory';
import PopupSafeHouseUpgrade from '../components/popup/PopupSafeHouseUpgrade';
import PopupBuyGoon from '../components/popup/PopupBuyGoon';
import PopupBuyThug from '../components/popup/PopupBuyThug';
import PopupBuyGangster from '../components/popup/PopupBuyGangster';
import PopupPortfolio from '../components/popup/PopupPortfolio';
import Animation from '../components/common/Animation';
import PopupStatistic from '../components/popup/PopupStatistic';
import PopupLeaderboard from '../components/popup/PopupLeaderboard';
import PopupDeposit from '../components/popup/PopupDeposit';
import PopupDepositETH from '../components/popup/PopupDepositETH';
import PopupReferralProgram from '../components/popup/PopupReferralProgram';
import PopupPrizePool from '../components/popup/PopupPrizePool';
import PopupWarMachines from '../components/popup/PopupWarMachines';
import PopupWarExplain from '../components/popup/PopupWarExplain';
import PopupWarAttack from '../components/popup/PopupWarAttack';
import PopupWarAttackDetail from '../components/popup/PopupWarAttackDetail';
import PopupWarHistoryDetail from '../components/popup/PopupWarHistoryDetail';
import PopupWarUpgrades from '../components/popup/PopupWarUpgrades';
import PopupGoonPrice from '../components/popup/PopupGoonPrice';
import PopupSafehousePrice from '../components/popup/PopupSafehousePrice';
import PopupGangsterPrice from '../components/popup/PopupGangsterPrice';
import PopupDailySpin from '../components/popup/PopupDailySpin';
import PopupSpinReward from '../components/popup/PopupSpinReward';
import PopupSpinHistory from '../components/popup/PopupSpinHistory';
import PopupPlaytestAirdrop from '../components/popup/PopupPlaytestAirdrop';
import PopupLinkX from '../components/popup/PopupLinkX';
import PopupGangwarRequirement from '../components/popup/PopupGangwarRequirement';
import PopupAirdrop from '../components/popup/PopupAirdrop';
import PopupThugAirdrop from '../components/popup/PopupThugAirdrop';
import PopupLinkingMetamask from '../components/popup/PopupLinkingMetamask';
import PopupClaimFreeThug from '../components/popup/PopupClaimFreeThug';
import PopupRewards from '../components/popup/PopupRewards';
import PopupStarterPack from '../components/popup/PopupStarterPack';

const { goonAnimation, gangsterAnimation, thugAnimation, width } = configs;

const gangsterBackAnimationSpeed = {
  x: Math.abs(gangsterAnimation.back.end.x - gangsterAnimation.back.start.x) / gangsterAnimation.back.time,
  y: Math.abs(gangsterAnimation.back.end.y - gangsterAnimation.back.start.y) / gangsterAnimation.back.time,
  scale: Math.abs(gangsterAnimation.back.end.scale - gangsterAnimation.back.start.scale) / gangsterAnimation.back.time,
};

const gangsterFrontAnimationSpeed = {
  x: Math.abs(gangsterAnimation.front.end.x - gangsterAnimation.front.start.x) / gangsterAnimation.front.time,
  y: Math.abs(gangsterAnimation.front.end.y - gangsterAnimation.front.start.y) / gangsterAnimation.front.time,
  scale:
    Math.abs(gangsterAnimation.front.end.scale - gangsterAnimation.front.start.scale) / gangsterAnimation.front.time,
};

const goonBackAnimationSpeed = {
  x: Math.abs(goonAnimation.back.end.x - goonAnimation.back.start.x) / goonAnimation.back.time,
  y: Math.abs(goonAnimation.back.end.y - goonAnimation.back.start.y) / goonAnimation.back.time,
  scale: Math.abs(goonAnimation.back.end.scale - goonAnimation.back.start.scale) / goonAnimation.back.time,
};

const goonFrontAnimationSpeed = {
  x: Math.abs(goonAnimation.front.end.x - goonAnimation.front.start.x) / goonAnimation.front.time,
  y: Math.abs(goonAnimation.front.end.y - goonAnimation.front.start.y) / goonAnimation.front.time,
  scale: Math.abs(goonAnimation.front.end.scale - goonAnimation.front.start.scale) / goonAnimation.front.time,
};

const thugBackAnimationSpeed = {
  x: Math.abs(thugAnimation.back.end.x - thugAnimation.back.start.x) / thugAnimation.back.time,
  y: Math.abs(thugAnimation.back.end.y - thugAnimation.back.start.y) / thugAnimation.back.time,
  scale: Math.abs(thugAnimation.back.end.scale - thugAnimation.back.start.scale) / thugAnimation.back.time,
};

const thugFrontAnimationSpeed = {
  x: Math.abs(thugAnimation.front.end.x - thugAnimation.front.start.x) / thugAnimation.front.time,
  y: Math.abs(thugAnimation.front.end.y - thugAnimation.front.start.y) / thugAnimation.front.time,
  scale: Math.abs(thugAnimation.front.end.scale - thugAnimation.front.start.scale) / thugAnimation.front.time,
};

class MainScene extends Phaser.Scene {
  isGameEnded = false;
  isUserActive = false;
  isFromTutorial = false;
  isSpinning = false;
  timeout = null;
  startGamePopup = {
    welcomeBackLoaded: false,
    playtestAirdropLoaded: false,
    claimedFreeThugLoaded: false,
    starterPackLoaded: false,
    open: null,
    data: null,
    hasOpened: false,
  };

  constructor() {
    super('MainScene');
  }

  init(data) {
    const { isFromTutorial } = data;
    this.isFromTutorial = isFromTutorial;
  }

  requestClaimableReward() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.game.events.emit('request-claimable-reward');
    }, 200);
  }

  preload() {
    this.load.script('chartjs', 'https://cdnjs.cloudflare.com/ajax/libs/Chart.js/3.8.0/chart.min.js');

    this.bgMusic = this.sound.add('bg', { loop: true, volume: 0.15 });

    this.background = new Background(this, 'bg');
    this.add.existing(this.background);

    this.animationLayer = new Animation(this);
    this.add.existing(this.animationLayer);

    const gangsterHouse = new GangsterHouse(this, 2200);
    this.add.existing(gangsterHouse);

    this.popupDepositETH = new PopupDepositETH(this);
    this.add.existing(this.popupDepositETH);

    this.popupDeposit = new PopupDeposit(this);
    this.add.existing(this.popupDeposit);

    this.popupBuy = new PopupBuy(this, width - 335, 1330);
    this.add.existing(this.popupBuy);

    const header = new Header(this, 250);
    this.add.existing(header);

    this.popupWar = new PopupWar(this, 35, 1600);
    this.add.existing(this.popupWar);

    this.popupSettings = new PopupSettings(this);
    this.add.existing(this.popupSettings);

    this.popupSwap = new PopupSwap(this, this.popupSettings);
    this.add.existing(this.popupSwap);

    this.popupReferralProgram = new PopupReferralProgram(this);
    this.add.existing(this.popupReferralProgram);

    this.popupStarterPack = new PopupStarterPack(this);
    this.add.existing(this.popupStarterPack);

    this.popupPortfolio = new PopupPortfolio(this, this.popupSettings);
    this.add.existing(this.popupPortfolio);

    this.popupStatistic = new PopupStatistic(this);
    this.add.existing(this.popupStatistic);

    this.popupWarAttackDetail = new PopupWarAttackDetail(this);
    this.add.existing(this.popupWarAttackDetail);

    this.popupWarHistoryDetail = new PopupWarHistoryDetail(this);
    this.add.existing(this.popupWarHistoryDetail);

    this.popupWarUpgrades = new PopupWarUpgrades(this);
    this.add.existing(this.popupWarUpgrades);

    this.popupRewards = new PopupRewards(this);
    this.add.existing(this.popupRewards);

    this.game.events.on('music-on', () => {
      this.bgMusic.play();
    });

    this.game.events.on('music-off', () => {
      this.bgMusic.stop();
    });

    this.game.events.on('update-workers-machines', ({ numberOfWorkers, numberOfMachines, numberOfThugs }) => {
      this.game.events.emit('update-gangster-animation', { numberOfMachines });
      this.game.events.emit('update-goon-animation', { numberOfWorkers });
      this.game.events.emit('update-thug-animation', { numberOfThugs });
    });

    this.game.events.emit('request-workers-machines');
    if (this.game.sound.mute) {
      this.bgMusic.stop();
    } else {
      this.bgMusic.play();
    }

    const pluginLoader = this.load.scenePlugin({
      key: 'rexuiplugin',
      url: '/libs/rexui.min.js',
      sceneKey: 'rexUI',
    });
    pluginLoader.once(Phaser.Loader.Events.COMPLETE, () => {
      this.popupAirdrop = new PopupAirdrop(this);
      this.add.existing(this.popupAirdrop);

      this.popupLinkingMetamask = new PopupLinkingMetamask(this);
      this.add.existing(this.popupLinkingMetamask);

      this.popupGoonPrice = new PopupGoonPrice(this);
      this.add.existing(this.popupGoonPrice);

      this.popupSafehousePrice = new PopupSafehousePrice(this);
      this.add.existing(this.popupSafehousePrice);

      this.popupGangsterPrice = new PopupGangsterPrice(this);
      this.add.existing(this.popupGangsterPrice);

      this.popupSafeHouseUpgrade = new PopupSafeHouseUpgrade(this);
      this.add.existing(this.popupSafeHouseUpgrade);

      this.popupBuyGoon = new PopupBuyGoon(this);
      this.add.existing(this.popupBuyGoon);

      this.popupBuyThug = new PopupBuyThug(this);
      this.add.existing(this.popupBuyThug);

      this.popupBuyGangster = new PopupBuyGangster(this);
      this.add.existing(this.popupBuyGangster);

      this.popupLeaderboard = new PopupLeaderboard(this);
      this.add.existing(this.popupLeaderboard);

      this.popupPrizePool = new PopupPrizePool(this);
      this.add.existing(this.popupPrizePool);

      this.popupWarHistory = new PopupWarHistory(this);
      this.add.existing(this.popupWarHistory);

      this.popupWarExplain = new PopupWarExplain(this);
      this.add.existing(this.popupWarExplain);

      this.popupWarMachines = new PopupWarMachines(this);
      this.add.existing(this.popupWarMachines);

      this.popupWarAttack = new PopupWarAttack(this);
      this.add.existing(this.popupWarAttack);

      this.popupDailySpin = new PopupDailySpin(this);
      this.add.existing(this.popupDailySpin);

      this.popupSpinHistory = new PopupSpinHistory(this);
      this.add.existing(this.popupSpinHistory);

      this.footer = new Footer(this, 2600);
      this.footer.setDepth(1);
      this.add.existing(this.footer);
    });

    this.popupSpinReward = new PopupSpinReward(this);
    this.popupSpinReward.setDepth(2);
    this.add.existing(this.popupSpinReward);

    this.infoButtons = new InfoButtons(this, 550);
    this.infoButtons.setDepth(2);
    this.add.existing(this.infoButtons);

    this.popupThugAirdrop = new PopupThugAirdrop(this);
    this.popupThugAirdrop.setDepth(2);
    this.add.existing(this.popupThugAirdrop);

    this.popupClaimFreeThug = new PopupClaimFreeThug(this);
    this.popupClaimFreeThug.setDepth(2);
    this.add.existing(this.popupClaimFreeThug);

    this.popupPlaytestAirdrop = new PopupPlaytestAirdrop(this);
    this.popupPlaytestAirdrop.setDepth(2);
    this.add.existing(this.popupPlaytestAirdrop);

    this.popupLinkX = new PopupLinkX(this);
    this.popupLinkX.setDepth(2);
    this.add.existing(this.popupLinkX);

    this.popupGangwarRequirement = new PopupGangwarRequirement(this);
    this.popupGangwarRequirement.setDepth(2);
    this.add.existing(this.popupGangwarRequirement);

    this.game.events.on('update-playtest-data', (data) => {
      if (data) {
        const { value, shared, claimed, claiming } = data;
        if (!claiming && !claimed) {
          this.startGamePopup.open = 'playtest-airdrop';
          this.startGamePopup.data = { value, shared };
        }
      }
      this.startGamePopup.playtestAirdropLoaded = true;

      this.openFirstPopup();
    });

    this.game.events.on('update-claimed-free-thugs', ({ claimedFreeThugs }) => {
      if (this.startGamePopup.open !== 'playtest-airdrop' && !claimedFreeThugs) {
        this.startGamePopup.open = 'free-thugs';
        this.startGamePopup.data = {};
      }
      this.startGamePopup.claimedFreeThugLoaded = true;

      this.openFirstPopup();
    });

    this.game.events.on('update-can-buy-starter-pack', ({ userCanBuy }) => {
      if (this.startGamePopup.open !== 'playtest-airdrop' && this.startGamePopup.open !== 'free-thugs' && userCanBuy) {
        this.startGamePopup.open = 'starter-pack';
        this.startGamePopup.data = {};
      }
      this.startGamePopup.starterPackLoaded = true;

      this.openFirstPopup();
    });

    this.game.events.on('update-user-away-reward', ({ noShow, showWarPopup, claimableReward, generatedNetworth }) => {
      if (noShow) {
        this.startGamePopup.welcomeBackLoaded = true;
        this.openFirstPopup();
        return;
      }
      this.popupWelcome = showWarPopup
        ? new PopupWelcomeWar(this, claimableReward, generatedNetworth)
        : new PopupWelcomeNoWar(this, claimableReward, generatedNetworth);
      this.add.existing(this.popupWelcome);
      if (!this.startGamePopup.open) {
        this.startGamePopup.open = 'welcome-back';
        this.startGamePopup.data = {};
      }

      this.startGamePopup.welcomeBackLoaded = true;

      this.openFirstPopup();
    });

    this.game.events.on('game-ended', ({ isEnded } = {}) => {
      if (isEnded && !this.popupEndGame) {
        this.popupEndGame = new PopupWelcomeEndGame(this);
        this.add.existing(this.popupEndGame);
        this.popupEndGame.open();
      }
      this.isGameEnded = true;
    });
    this.game.events.on('update-active-status', ({ active }) => {
      this.isUserActive = active;
    });

    this.game.events.emit('request-game-ended-status');
    this.game.events.emit('request-active-status');
    this.game.events.emit('request-deposit-code');
    if (!this.isFromTutorial) {
      this.game.events.emit('request-user-away-reward');
    } else {
      this.startGamePopup.welcomeBackLoaded = true;
    }
    this.game.events.emit('request-claimed-free-thugs');
  }

  create() {}

  openFirstPopup() {
    if (this.isGameEnded) return;
    const {
      welcomeBackLoaded,
      claimedFreeThugLoaded,
      playtestAirdropLoaded,
      starterPackLoaded,
      open,
      data,
      hasOpened,
    } = this.startGamePopup;
    if (!playtestAirdropLoaded || !claimedFreeThugLoaded || !starterPackLoaded || !welcomeBackLoaded || hasOpened)
      return;

    if (open === 'play-test') {
      this.popupPlaytestAirdrop?.show(data);
      this.startGamePopup.hasOpened = true;
    }

    if (open === 'free-thugs') {
      this.popupClaimFreeThug?.open(data);
      this.startGamePopup.hasOpened = true;
    }

    if (open === 'starter-pack') {
      this.popupStarterPack?.open(data);
      this.startGamePopup.hasOpened = true;
    }

    if (open === 'welcome-back') {
      this.popupWelcome?.open(data);
      this.startGamePopup.hasOpened = true;
    }
  }

  updateAnimationPositions(delta) {
    if (this.animationLayer.gangsterAction === 'back') {
      const { x, y } = this.animationLayer.gangsterBack;
      if (x >= gangsterAnimation.back.end.x || y <= gangsterAnimation.back.end.y) {
        this.game.events.emit('animation-gangster-front');
      } else {
        const newX = Math.min(
          this.animationLayer.gangsterBack.x + gangsterBackAnimationSpeed.x * delta,
          gangsterAnimation.back.end.x
        );
        const newY = Math.max(
          this.animationLayer.gangsterBack.y - gangsterBackAnimationSpeed.y * delta,
          gangsterAnimation.back.end.y
        );
        const newScale = Math.max(
          this.animationLayer.gangsterBack.scale - gangsterBackAnimationSpeed.scale * delta,
          gangsterAnimation.back.end.scale
        );
        this.animationLayer.gangsterBack.x = newX;
        this.animationLayer.gangsterBack.y = newY;
        this.animationLayer.gangsterBack.setScale(newScale);
        this.animationLayer.updateGangsterCounter(newX, newY - newScale * 500);
      }
    }

    if (this.animationLayer.gangsterAction === 'front') {
      const { y } = this.animationLayer.gangsterFront;
      if (y >= gangsterAnimation.front.end.y) {
        this.game.events.emit('animation-gangster-back');
        // this.game.events.emit('request-claimable-reward');
        this.game.events.emit('check-game-ended');
        this.requestClaimableReward();
      } else {
        const newY = Math.min(
          this.animationLayer.gangsterFront.y + gangsterFrontAnimationSpeed.y * delta,
          gangsterAnimation.front.end.y
        );
        const newScale = Math.min(
          this.animationLayer.gangsterFront.scale + gangsterFrontAnimationSpeed.scale * delta,
          gangsterAnimation.front.end.scale
        );
        this.animationLayer.gangsterFront.y = newY;
        this.animationLayer.gangsterFront.setScale(newScale);
        this.animationLayer.updateGangsterCounter(this.animationLayer.gangsterFront.x, newY - newScale * 500);
      }
    }

    if (this.animationLayer.goonAction === 'back') {
      const { x, y } = this.animationLayer.goonBack;

      if (x <= goonAnimation.back.end.x || y <= goonAnimation.back.end.y) {
        this.game.events.emit('animation-goon-front');
      } else {
        const newX = Math.max(
          this.animationLayer.goonBack.x - goonBackAnimationSpeed.x * delta,
          goonAnimation.back.end.x
        );
        const newY = Math.max(
          this.animationLayer.goonBack.y - goonBackAnimationSpeed.y * delta,
          goonAnimation.back.end.y
        );
        const newScale = Math.max(
          this.animationLayer.goonBack.scale - goonBackAnimationSpeed.scale * delta,
          goonAnimation.back.end.scale
        );
        this.animationLayer.goonBack.x = newX;
        this.animationLayer.goonBack.y = newY;
        this.animationLayer.goonBack.setScale(newScale);
        this.animationLayer.updateGoonCounter(newX, newY - newScale * 600);
      }
    }

    if (this.animationLayer.goonAction === 'front') {
      const { y } = this.animationLayer.goonFront;

      if (y >= goonAnimation.front.end.y) {
        this.game.events.emit('animation-goon-back');
        this.game.events.emit('check-game-ended');
      } else {
        const newY = Math.min(
          this.animationLayer.goonFront.y + goonFrontAnimationSpeed.y * delta,
          goonAnimation.front.end.y
        );
        const newScale = Math.min(
          this.animationLayer.goonFront.scale + goonFrontAnimationSpeed.scale * delta,
          goonAnimation.front.end.scale
        );
        this.animationLayer.goonFront.y = newY;
        this.animationLayer.goonFront.setScale(newScale);
        this.animationLayer.updateGoonCounter(this.animationLayer.goonFront.x, newY - newScale * 600);
      }
    }

    if (this.animationLayer.thugAction === 'back') {
      const { x, y } = this.animationLayer.thugBack;

      if (y <= thugAnimation.back.end.y) {
        this.game.events.emit('animation-thug-front');
      } else {
        const newX = Math.max(
          this.animationLayer.thugBack.x - thugBackAnimationSpeed.x * delta,
          thugAnimation.back.end.x
        );
        const newY = Math.max(
          this.animationLayer.thugBack.y - thugBackAnimationSpeed.y * delta,
          thugAnimation.back.end.y
        );
        const newScale = Math.max(
          this.animationLayer.thugBack.scale - thugBackAnimationSpeed.scale * delta,
          thugAnimation.back.end.scale
        );
        this.animationLayer.thugBack.x = newX;
        this.animationLayer.thugBack.y = newY;
        this.animationLayer.thugBack.setScale(newScale);
        this.animationLayer.updateThugCounter(newX, newY - newScale * 600);
      }
    }

    if (this.animationLayer.thugAction === 'front') {
      const { y } = this.animationLayer.thugFront;

      if (y >= thugAnimation.front.end.y) {
        this.game.events.emit('animation-thug-back');
        this.game.events.emit('check-game-ended');
        this.game.events.emit('request-poor-token-balance');
      } else {
        const newY = Math.min(
          this.animationLayer.thugFront.y + thugFrontAnimationSpeed.y * delta,
          thugAnimation.front.end.y
        );
        const newScale = Math.min(
          this.animationLayer.thugFront.scale + thugFrontAnimationSpeed.scale * delta,
          thugAnimation.front.end.scale
        );
        this.animationLayer.thugFront.y = newY;
        this.animationLayer.thugFront.setScale(newScale);
        this.animationLayer.updateThugCounter(this.animationLayer.thugFront.x, newY - newScale * 600);
      }
    }
  }

  update(_time, delta) {
    this.updateAnimationPositions(delta);
  }
}

export default MainScene;
