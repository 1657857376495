import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Phaser from 'phaser';
import CircleMaskImagePlugin from 'phaser3-rex-plugins/plugins/circlemaskimage-plugin.js';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { usePrivy } from '@privy-io/react-auth';
import * as Sentry from '@sentry/react';
import moment from 'moment';

import useUserStore from '../../stores/user.store';
import useSystemStore from '../../stores/system.store';
import useSettingStore from '../../stores/setting.store';
import usePrivyStore from '../../stores/privy.store';
import {
  getRank,
  getWarHistory,
  getWarHistoryDetail,
  updateBalance,
  unlinkTwitter,
  getXCsrfToken,
  getAirdropClaimStatus,
  shareAirdrop,
  claimAirdrop,
  getReward,
  getAirdropWalletList,
  claimThug,
  claimFreeThug,
  applyInviteCode,
  getReferralDiscount,
  getUserRewards,
} from '../../services/user.service';
import {
  create,
  validate,
  claimToken,
  getWorkerPrices,
  getBuildingPrices,
  getMachinePrices,
  validateDailySpin,
  rollbackDailySpin,
  rollbackBuyStarterPack,
  buyAssetsWithXToken,
  getSpinTxns,
  validateGameTxn,
  buyThugsWithPoorToken,
} from '../../services/transaction.service';
import {
  getUPointLeaderboard,
  getNextWarSnapshotUnixTime,
  updateLastTimeSeenGangWarResult,
  updateUserWarDeployment,
  getNextSpinIncrementUnixTime,
  upgradeUserMachines,
  getLeaderboard,
  getRaidPointLeaderboard,
} from '../../services/gamePlay.service';
import {
  getLatestWar,
  getUserListToAttack,
  getUserDetailToAttack,
  getLatestWarResult,
} from '../../services/war.service';
import { getOauthRequestToken } from '../../services/twitter.service';
import {
  getAuctionItems,
  createBidding,
  getBiddingHistory,
  getAuctionItemHistory,
  getAuctionItemHistoryDetail,
  getAuctionEndTime,
} from '../../services/auction.service';
import QueryKeys from '../../utils/queryKeys';
import { calculateHouseLevel, calculateSpinPrice } from '../../utils/formulas';
import useSmartContract from '../../hooks/useSmartContract';
import purchaseConfig from '../../assets/jsons/purchaseConfig.json';

import gameConfigs, { windowHeight } from './configs/configs';
import LoadingScene from './scenes/LoadingScene';
import MainScene from './scenes/MainScene';
import TutorialScene from './scenes/TutorialScene';
import AuctionScene from './scenes/AuctionScene';

import useWagmi from '../../hooks/useWagmi';
import useUserWallet from '../../hooks/useUserWallet';
import useSeasonCountdown from '../../hooks/useSeasonCountdown';
import useStarterPackCountdown from '../../hooks/useStarterPackCountdown';
import useBlastGoldSnapshotCountdown from '../../hooks/useBlastGoldSnapshotCountdown';
import useSimulatorGameListener from '../../hooks/useSimulatorGameListener';
import usePriceTracking from '../../hooks/usePriceTracking';
import { logAnalyticsEvent } from '../../configs/firebase.config';
import { useShallow } from 'zustand/react/shallow';

const { width, height } = gameConfigs;
const MILISECONDS_IN_A_DAY = 86400 * 1000;
const MILLISECONDS_PER_MINUTE = 60 * 1000;
const cacheTime = 1 * 60 * 1000;

const lineBreakMessage = (message) => {
  const MAX_WORD_LENGTH = 20;
  if (message.length <= MAX_WORD_LENGTH) return message;

  const words = message.trim().split(' ');
  const brokenWords = [words[0]];
  for (let i = 1; i < words.length; i++) {
    const newWord = words[i];
    const lastWord = brokenWords.at(-1);
    if (lastWord.length + newWord.length + 1 <= MAX_WORD_LENGTH) {
      brokenWords[brokenWords.length - 1] = brokenWords.at(-1) + ` ${newWord}`;
    } else {
      brokenWords.push(newWord);
    }
  }

  return brokenWords.join('\n');
};

const handleError = (err) => {
  try {
    if (err.message === 'The user rejected the request') {
      return { code: '4001', message: 'The user rejected\nthe request' };
    } else {
      console.error(err);

      const message = err.message;
      const code = err.code?.toString();

      console.log({ message, code, reason: err?.reason, error: err?.error?.reason });

      if (message === 'Network Error') {
        return { code: '12002', message: 'Network Error' };
      }

      if (message.includes('replacement fee too low')) return { code: '4001', message: 'Replacement fee\ntoo low' };

      if (message.includes('Transaction reverted without a reason string'))
        return { code: '4001', message: 'Transaction reverted' };

      if (message.includes('Request failed with status code 422')) return { code: '4001', message: 'Request failed' };

      if (message.includes('invalid address or ENS name')) return { code: '4001', message: 'Invalid address\nor ENS' };

      if (message.includes('User exited before wallet could be connected'))
        return { code: '4001', message: 'User exited' };

      if (message.includes('transaction failed')) return { code: '4001', message: 'Transaction failed' };

      if (message.includes('missing response')) return { code: '4001', message: 'Missing response' };

      if (message.includes('Cannot redefine property: ethereum'))
        return { code: '4001', message: 'Cannot redefine\nethereum' };

      if (message.includes('insufficient funds for intrinsic transaction cost'))
        return { code: 'INSUFFICIENT_FUNDS', message: 'Insufficient ETH' };

      if (code === 'UNPREDICTABLE_GAS_LIMIT' || code === '-32603') {
        if (message === 'Wallet has insufficient funds for this transaction.')
          return { code: 'INSUFFICIENT_FUNDS', message: 'Insufficient Gas' };
        if (err?.error?.reason && err.error?.reason.includes('execution reverted:')) {
          const error = err.error?.reason.replace('execution reverted: ', '');
          return { code: 'UNPREDICTABLE_GAS_LIMIT', message: error ? lineBreakMessage(error) : 'TRANSACTION FAILED' };
        }

        return { code: 'UNPREDICTABLE_GAS_LIMIT', message: 'TRANSACTION FAILED' };
      }

      if (code === 'INSUFFICIENT_FUNDS') return { code: 'INSUFFICIENT_FUNDS', message: 'INSUFFICIENT ETH' };

      if (code === 'INVALID_ARGUMENT') return { code: 'INVALID_ARGUMENT', message: 'INVALID ARGUMENT' };

      if (code === 'NETWORK_ERROR') return { code: 'NETWORK_ERROR', message: 'Network Error' };

      Sentry.captureException(err);
      return { code: '4001', message: 'Unknown Error' };
    }
  } catch (err) {
    Sentry.captureException(err);
    return { code: '4001', message: 'Unknown Error' };
  }
};

const Game = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { userWallet } = useUserWallet();
  const queryClient = useQueryClient();
  const [userHasInteractive, setUserHasInteracted] = useState(false);
  const cachedRef = useRef({
    auctionItemHistory: {},
    auctionItemHistoryDetail: {},
  });
  const gameRef = useRef();
  const gameLoaded = useRef();
  const gameEventListened = useRef();
  const [loaded, setLoaded] = useState(false);
  const profile = useUserStore((state) => state.profile);
  const gamePlay = useUserStore((state) => state.gamePlay);
  const reloadWarDeployment = useUserStore((state) => state.reloadWarDeployment);
  const activeSeason = useSystemStore((state) => state.activeSeason);
  const activeSeasonId = useSystemStore(useShallow((state) => state.activeSeason?.id));
  const activeSeasonEstimatedEndTime = useSystemStore(
    useShallow((state) => state.activeSeason?.estimatedEndTime.seconds)
  );
  const warConfig = useSystemStore(
    useShallow((state) => state.activeSeason?.warConfig),
    (a, b) => JSON.stringify(a) === JSON.stringify(b)
  );
  const warState = useSystemStore(
    useShallow((state) => state.activeSeason?.warState),
    (a, b) => JSON.stringify(a) === JSON.stringify(b)
  );
  const configs = useSystemStore((state) => state.configs);
  const market = useSystemStore((state) => state.market);
  const templates = useSystemStore((state) => state.templates);
  const estimatedGas = useSystemStore((state) => state.estimatedGas);
  const sound = useSettingStore((state) => state.sound);
  const toggleSound = useSettingStore((state) => state.toggleSound);
  const setOnlineListener = useSettingStore((state) => state.setOnlineListener);
  const setIsCustomContainer = usePrivyStore((state) => state.setIsCustomContainer);
  const {
    getNFTBalance,
    getETHBalance,
    withdrawToken,
    withdrawETH,
    withdrawNFT,
    stakeNFT,
    buyMachine,
    buyGoon,
    buyPistol,
    buyShield,
    dailySpin,
    buyStartPack,
    swapEthToToken,
    swapTokenToEth,
    convertEthInputToToken,
    convertEthOutputToToken,
    convertTokenInputToEth,
    convertTokenOutputToEth,
    getTotalFees,
  } = useSmartContract();
  const { connectors, address: wagmiAddress, isConnecting, isConnected, connect, disconnect, signMessage } = useWagmi();
  const { ready, authenticated, user, exportWallet: exportWalletPrivy, logout } = usePrivy();
  const [isLeaderboardModalOpen, setLeaderboardModalOpen] = useState(false);
  const [isStarterPackModalOpen, setStarterPackModalOpen] = useState(false);
  const [userCanReload, setUserCanReload] = useState(false);
  const [mouseDown, setMouseDown] = useState(false);
  const [startLoadingTime, setStartLoadingTime] = useState(Date.now());
  const { started, isEnded, countdownString, startTimeString, midSeasonCountdownString } = useSeasonCountdown({
    open: isLeaderboardModalOpen,
  });
  const blastGoldSnapshotCountdown = useBlastGoldSnapshotCountdown({ open: isLeaderboardModalOpen });
  const starterPackCountdown = useStarterPackCountdown({ open: isStarterPackModalOpen });
  const [showBg, setShowBg] = useState(true);
  const {
    listeningWorker,
    listeningBuilding,
    listeningMachine,
    listeningThug,
    enableWorkerSalesTracking,
    disableWorkerSalesTracking,
    enableBuildingSalesTracking,
    disableBuildingSalesTracking,
    enableMachineSalesTracking,
    disableMachineSalesTracking,
    enableThugSalesTracking,
    disableThugSalesTracking,
  } = usePriceTracking();

  useLayoutEffect(() => {
    setIsCustomContainer(false);
  }, []);

  const reloadUserWarDeployment = async () => {
    try {
      await reloadWarDeployment();
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const { appVersion, appReloadThresholdInSeconds } = configs || {};
  const { ethPriceInUsd, tokenPrice, nftPrice } = market || {};

  // Check that your user is authenticated
  const isAuthenticated = useMemo(() => ready && authenticated, [ready, authenticated]);

  const { status, data: rankData } = useQuery({
    queryFn: getRank,
    queryKey: [QueryKeys.Rank, profile?.id],
    enabled: !!profile?.id,
    refetchInterval: 60 * 1000,
    staleTime: 60 * 1000,
    retry: 3,
    onError: (err) => {
      Sentry.captureException(err);
    },
  });
  // const { data: leaderboardData } = useQuery({
  //   queryKey: [QueryKeys.Leaderboard],
  //   queryFn: getLeaderboard,
  //   // refetchInterval: 30 * 1000,
  //   staleTime: 120000,
  //   retry: 3,
  //   onError: (err) => {
  //     Sentry.captureException(err);
  //   },
  // });

  const {
    username,
    address,
    socials,
    avatarURL,
    avatarURL_big,
    tokenBalance,
    ETHBalance,
    inviteCode,
    referralCode,
    referralTotalReward,
    referralTotalDiscount,
    referralProgramType,
  } = profile || {
    tokenBalance: 0,
    ETHBalance: 0,
    inviteCode: '',
    referralCode: '',
    referralTotalReward: 0,
    referralTotalDiscount: 0,
    referralProgramType: 'default',
  };

  const { setupSimulatorGameListener } = useSimulatorGameListener();
  const {
    xTokenBalance,
    numberOfMachines,
    numberOfAvailableMachines,
    numberOfWorkers,
    numberOfBuildings,
    numberOfPistols,
    numberOfShields,
    numberOfThugs,
    networth,
    raidPoint,
    isWhitelisted,
    whitelistAmountMinted,
    warDeployment,
    uPointReward,
    blastPointReward,
    twitterVerified,
    latestWarChain,
    packsPurchased,
  } = gamePlay || {
    xTokenBalance: 0,
    numberOfMachines: 0,
    numberOfWorkers: 0,
    numberOfBuildings: 0,
    numberOfThugs: 0,
    numberOfPistols: 0,
    numberOfShields: 0,
    networth: 0,
    raidPoint: 0,
    whitelistAmountMinted: 0,
    warDeployment: {
      numberOfMachinesToEarn: 0,
      numberOfMachinesToAttack: 0,
      numberOfMachinesToDefend: 0,
      attackUserId: null,
    },
    uPointReward: 0,
    blastPointReward: 0,
    twitterVerified: false,
    latestWarChain: { result: null, numberOfWars: 0 },
    packsPurchased: {},
  };
  const {
    machine,
    worker,
    thug,
    building,
    pistol,
    shield,
    houseLevels,
    prizePoolEth,
    prizePoolToken,
    blastGoldReward,
    xUReward,
    prizePoolConfig,
    midSeasonSnapshotTaken,
    auctionState: { nextAuctionPrizePool = 0 } = {},
    spinConfig: { spinRewards, tokenReputationRewardMutiplier, costReputationMultiplier, basePrice },
  } = activeSeason || {
    machine: { dailyReward: 0, basePrice: 0, whitelistPrice: 0, networthBase: 0, networthPerDay: 0 },
    worker: {
      basePrice: 0,
      targetDailyPurchase: 1,
      targetPrice: 0,
      dailyReward: 0,
      networthBase: 0,
      networthPerDay: 0,
    },
    thug: {
      basePrice: 0,
      targetDailyPurchase: 1,
      targetPrice: 0,
      dailyReward: 0,
      networthBase: 0,
      networthPerDay: 0,
    },
    building: {
      basePrice: 0,
      targetDailyPurchase: 1,
      targetPrice: 0,
      dailyReward: 0,
      networthBase: 0,
      networthPerDay: 0,
    },
    pistol: {
      price: 0,
      maxPerBatch: 0,
      attackPoint: 0,
    },
    shield: {
      price: 0,
      maxPerBatch: 0,
      defencePoint: 0,
    },
    buildingSold: 0,
    workerSold: 0,
    machineSold: 0,
    houseLevels: [],
    prizePoolEth: 0,
    prizePoolToken: 0,
    blastGoldReward: 0,
    xUReward: 0,
    auctionState: { nextAuctionPrizePool: 0 },
    prizePoolConfig: {},
    spinConfig: { spinRewards: [], tokenReputationRewardMutiplier: 0, costReputationMultiplier: 1, basePrice: 500 },
  };

  const dailyXToken = numberOfMachines * gamePlay?.machine?.dailyReward + numberOfWorkers * worker.dailyReward;

  useEffect(() => {
    setStartLoadingTime(Date.now());
  }, []);

  useEffect(() => {
    if (!appReloadThresholdInSeconds || userCanReload) return;
    const elapsedTime = Date.now() - startLoadingTime;
    const remainingTime = appReloadThresholdInSeconds * 1000 - elapsedTime;

    if (remainingTime <= 0) {
      setUserCanReload(true);
    } else {
      const timer = setTimeout(() => {
        setUserCanReload(true);
      }, remainingTime);

      return () => clearTimeout(timer);
    }
  }, [userCanReload, startLoadingTime, appReloadThresholdInSeconds]);

  const exportWallet = async () => {
    console.log('export wallet', { userWallet, isAuthenticated });
    if (!isAuthenticated || userWallet?.walletClientType !== 'privy') return;
    try {
      await exportWalletPrivy();
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };
  const reloadBalance = async () => {
    try {
      await updateBalance();
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    } finally {
      gameRef.current?.events.emit('refresh-eth-balance-completed');
    }
  };

  const transfer = async ({ amount, address, tokenType }) => {
    let web3Withdraw, txnStartedEvent, txnCompletedEvent;
    switch (tokenType) {
      case 'GREED':
        web3Withdraw = withdrawToken;
        txnStartedEvent = 'withdraw-token-started';
        txnCompletedEvent = 'withdraw-token-completed';
        break;
      case 'ETH':
        web3Withdraw = withdrawETH;
        txnStartedEvent = 'withdraw-eth-started';
        txnCompletedEvent = 'withdraw-eth-completed';
        break;
      case 'NFT':
        web3Withdraw = withdrawNFT;
        txnStartedEvent = 'withdraw-nft-started';
        txnCompletedEvent = 'withdraw-nft-completed';
        break;
    }
    try {
      if (!web3Withdraw) throw new Error(`Invalid tokenType. Must be one of 'ETH' | 'GREED' | 'NFT'`);
      gameRef.current?.events.emit(txnStartedEvent);

      const value = Number(amount);
      let txnId;
      if (tokenType === 'ETH') {
        const res = await create({ type: 'withdraw', token: tokenType, value, to: address });
        txnId = res.data.id;
      }
      const receipt = await web3Withdraw(address, value);
      if (receipt) {
        gameRef.current?.events.emit(txnCompletedEvent, { amount, txnHash: receipt.transactionHash });
        if (receipt.status === 1) {
          if (txnId && ['ETH', 'GREED'].includes(tokenType))
            await validate({ transactionId: txnId, txnHash: receipt.transactionHash });
          if (tokenType === 'NFT') {
            validateGameTxn({ txnHash: receipt.transactionHash, type: 'withdraw-machine' }).catch((err) => {
              console.error(err);
              Sentry.captureException(err);
            });
          }
        }
      }
    } catch (err) {
      const { message, code } = handleError(err);
      gameRef.current?.events.emit(txnCompletedEvent, {
        status: 'failed',
        code,
        message,
        amount,
        txnHash: '',
      });
    }
  };

  const stake = async (amount) => {
    try {
      gameRef.current?.events.emit('deposit-nft-started');

      const receipt = await stakeNFT(address, amount);
      if (receipt.status === 1) {
        validateGameTxn({ txnHash: receipt.transactionHash, type: 'deposit-machine' }).catch((err) => {
          console.error(err);
          Sentry.captureException(err);
        });
        gameRef.current?.events.emit('deposit-nft-completed', { amount, txnHash: receipt.transactionHash });
      }
    } catch (err) {
      const { message, code } = handleError(err);
      gameRef.current?.events.emit('deposit-nft-completed', {
        status: 'failed',
        code,
        message,
      });

      console.error(err);
      Sentry.captureException(err);
    }
  };

  const buyBuilding = async ({ quantity }) => {
    try {
      await buyAssetsWithXToken({ type: 'building', amount: quantity });
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const buyWorker = async ({ quantity, token }) => {
    try {
      if (token === 'xGREED') {
        await buyAssetsWithXToken({ type: 'worker', amount: quantity });
      } else {
        const res = await create({ type: 'buy-worker', amount: quantity, token });
        const { id, amount, value, time, referrerAddress, referralBonusAmount, nonce, signature, lastB } = res.data;
        const receipt = await buyGoon({
          amount,
          value: token === 'GREED' ? value : 0,
          lastB,
          time,
          referrerAddress,
          referralBonusAmount,
          nonce,
          signature,
        });

        if (receipt.status !== 1) throw new Error('Transaction failed');
        validateGameTxn({ txnHash: receipt.transactionHash, type: 'buy-worker' }).catch((err) => {
          console.error(err);
          Sentry.captureException(err);
        });
        return receipt.transactionHash;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const buyGangster = async (quantity, mintFunction) => {
    try {
      const res = await create({ type: 'buy-machine', amount: quantity, mintFunction });
      const { id, amount, value, time, lastB, referrerAddress, referralBonusAmount, nonce, signature } = res.data;
      const receipt = await buyMachine({
        amount,
        value,
        time,
        lastB,
        referrerAddress,
        referralBonusAmount,
        nonce,
        signature,
      });
      if (receipt.status === 1) {
        validateGameTxn({ txnHash: receipt.transactionHash, type: 'buy-machine' }).catch((err) => {
          console.error(err);
          Sentry.captureException(err);
        });
        return receipt.transactionHash;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const buyWarUpgrades = async ({ quantity, type, token }) => {
    if (!quantity) return;
    try {
      if (token === 'xGREED') {
        await buyAssetsWithXToken({ type, amount: quantity });
      } else {
        const res = await create({ type: `buy-${type}`, amount: quantity, token });
        const { amount, value, time, referrerAddress, referralBonusAmount, nonce, signature, lastB } = res.data;
        const buyFunction = type === 'pistol' ? buyPistol : buyShield;
        const receipt = await buyFunction({
          amount,
          value: token === 'GREED' ? value : 0,
          lastB,
          time,
          referrerAddress,
          referralBonusAmount,
          nonce,
          signature,
        });

        if (receipt.status !== 1) throw new Error('Transaction failed');
        validateGameTxn({ txnHash: receipt.transactionHash, type: `buy-${type}` }).catch((err) => {
          console.error(err);
          Sentry.captureException(err);
        });
        return receipt.transactionHash;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const buyStarterPack = async ({ packId }) => {
    if (!packId) return;
    let transactionId, txnHash;

    try {
      const res = await create({ type: `buy-starter-pack`, packId });
      const { id, contractPackId, value, time, nonce, signature } = res.data;
      transactionId = id;
      const receipt = await buyStartPack({
        packId: contractPackId,
        value,
        time,
        nonce,
        signature,
      });
      txnHash = receipt.transactionHash;

      if (receipt.status !== 1) throw new Error('Transaction failed');
      validateGameTxn({ txnHash, type: `buy-starter-pack` }).catch((err) => {
        console.error(err);
        Sentry.captureException(err);
      });

      return txnHash;
    } catch (err) {
      if (transactionId) {
        Sentry.captureException(err);
        rollbackBuyStarterPack({ transactionId, txnHash }).catch((e) => {
          console.error(`Err while rollback buy starter pack: ${e.message}`);
          Sentry.captureException(e);
        });
      }

      console.error(err);
      throw err;
    }
  };

  const initDailySpin = async () => {
    let transactionId, txnHash;
    try {
      const res = await create({ type: 'daily-spin' });
      const { id, spinType, amount, value, lastSpin, time, nonce, signature } = res.data;
      transactionId = id;
      const receipt = await dailySpin({ spinType, amount, value, lastSpin, time, nonce, signature });
      txnHash = receipt.transactionHash;
      if (receipt.status !== 1) {
        throw new Error('Transaction daily spin failed');
      }
      const res1 = await validateDailySpin({ transactionId, txnHash });
      const { result } = res1.data;
      gameRef.current?.events.emit('spin-result', { destinationIndex: result });

      // test
      // await delay(5000);
      // gameRef.current?.events.emit('spin-result', { destinationIndex: Math.floor(Math.random() * 14) });
    } catch (err) {
      // if (transactionId) {
      //   Sentry.captureException(err);
      //   rollbackDailySpin({ transactionId, txnHash }).catch((e) => {
      //     console.error(`Err while rollback daily spin: ${e.message}`);
      //     Sentry.captureException(e);
      //   });
      // }
      Sentry.captureException(err);
      console.error(err);
      throw err;
    }
  };

  const calculateNetworthRef = useRef();
  calculateNetworthRef.current = () => {
    if (!activeSeason || !gamePlay?.startNetworthCountingTime)
      return gameRef.current?.events.emit('update-networth', {
        networth: Math.floor(networth),
        networthPerDay: 0,
        level: calculateHouseLevel(houseLevels, networth),
      });
    const end = Math.min(Date.now(), activeSeason?.estimatedEndTime.toDate().getTime());
    const diffInDays = (end - gamePlay.startNetworthCountingTime.toDate().getTime()) / MILISECONDS_IN_A_DAY;
    const networthPerDay =
      numberOfMachines * machine.networthPerDay +
      numberOfBuildings * building.networthPerDay +
      numberOfWorkers * worker.networthPerDay;
    let generatedNetworth = networthPerDay * diffInDays;
    if (activeSeason?.startTime?.toDate().getTime() > Date.now()) {
      generatedNetworth = 0;
    }

    const calculatedNetworth = Math.floor(networth + generatedNetworth);

    gameRef.current?.events.emit('update-networth', {
      networth: calculatedNetworth,
      networthPerDay,
      level: calculateHouseLevel(houseLevels, calculatedNetworth),
    });
  };

  const calculateClaimableRewardRef = useRef();
  calculateClaimableRewardRef.current = () => {
    if (!gamePlay?.startXTokenCountingTime) return;

    const diffInDays = (Date.now() - gamePlay.startXTokenCountingTime.toDate().getTime()) / MILISECONDS_IN_A_DAY;
    let claimableReward = gamePlay.pendingXToken + diffInDays * dailyXToken;
    if (activeSeason?.startTime?.toDate().getTime() > Date.now()) {
      claimableReward = 0;
    }
    gameRef.current?.events.emit('update-claimable-reward', { reward: claimableReward });
    gameRef.current?.events.emit('claimable-reward-added');
  };

  const checkGameEndRef = useRef();
  checkGameEndRef.current = () => {
    if (!activeSeason) return;
    const { startTime, estimatedEndTime, status } = activeSeason;
    const now = Date.now();
    const gameStartTime = startTime.toDate().getTime();
    const endTime = estimatedEndTime.toDate().getTime();
    const isNotOpen = now >= endTime || status !== 'open';
    const isEnded = now >= endTime || (status !== 'open' && now > gameStartTime);
    if (isNotOpen) {
      gameRef.current?.events.emit('stop-animation');
      gameRef.current?.events.emit('game-ended', { isEnded });
    }
  };

  const updateNextAuctionPrize = useRef();
  updateNextAuctionPrize.current = () => {
    gameRef.current?.events.emit('load-next-auction-prize', { nextAuctionPrizePool });
  };

  const updateSpinRewardRef = useRef();
  updateSpinRewardRef.current = () => {
    if (!activeSeason || !gamePlay?.startNetworthCountingTime)
      return gameRef.current?.events.emit('update-spin-rewards', {
        spinRewards: JSON.parse(JSON.stringify(spinRewards))
          .sort((item1, item2) => item1.order - item2.order)
          .map((item) => {
            if (item.type === 'GREED')
              return {
                ...item,
                value: Math.floor(item.value * (1 + tokenReputationRewardMutiplier * Math.floor(networth))),
              };
            return item;
          }),
        spinPrice: calculateSpinPrice(Math.floor(networth), costReputationMultiplier, basePrice),
      });

    const now = Date.now();
    const { startNetworthCountingTime } = gamePlay;
    const networthPerDay =
      numberOfMachines * machine.networthPerDay +
      numberOfWorkers * worker.networthPerDay +
      numberOfBuildings * building.networthPerDay;
    const networthDiffInDays = (now - startNetworthCountingTime.toDate().getTime()) / MILISECONDS_IN_A_DAY;
    const generatedNetworth = networthPerDay * networthDiffInDays;
    const calculatedNetworth = Math.floor(networth + generatedNetworth);

    gameRef.current?.events.emit('update-spin-rewards', {
      spinRewards: JSON.parse(JSON.stringify(spinRewards))
        .sort((item1, item2) => item1.order - item2.order)
        .map((item) => {
          if (item.type === 'GREED')
            return {
              ...item,
              value: Math.floor(item.value * (1 + tokenReputationRewardMutiplier * calculatedNetworth)),
            };
          return item;
        }),
      spinPrice: calculateSpinPrice(calculatedNetworth, costReputationMultiplier, basePrice),
    });
  };

  const updateMachineRef = useRef();
  updateMachineRef.current = () => {
    if (!activeSeason) return;
    const now = Date.now();
    const gameDays = (now - activeSeason.startTime.toDate().getTime()) / MILISECONDS_IN_A_DAY;
    const minutes = (now - activeSeason.startTime.toDate().getTime()) / MILLISECONDS_PER_MINUTE;
    const fakePurchases = purchaseConfig[`${Math.floor(minutes)}`] || purchaseConfig['default'];
    gameRef.current?.events.emit('update-machines', {
      numberOfMachines,
      balance: tokenBalance,
      maxPerBatch: machine.maxPerBatch,
      dailyReward: gamePlay?.machine?.dailyReward || machine.dailyReward,
      earningRateIncrementPerLevel: activeSeason?.machine?.earningRateIncrementPerLevel,
      level: gamePlay?.machine?.level,
      networthBase: machine.networthBase,
      networthPerDay: machine.networthPerDay,
      tokenPrice,
      isWhitelisted,
      whitelistAmountLeft: machine.maxWhitelistAmount - whitelistAmountMinted,
      basePrice: machine.basePrice,
      basePriceWhitelist: machine.whitelistPrice,
      targetDailyPurchase: machine.targetDailyPurchase,
      pricePower: machine.pricePower,
      targetPrice: machine.targetPrice,
      levelPower: machine.levelPower,
      levelMultiple: machine.levelMultiple,
      totalSold: activeSeason?.machineSold + fakePurchases.numberOfMachineFakePurchases,
      days: gameDays,
      isTrackingSales: listeningMachine,
      started: now > activeSeason.startTime.toDate().getTime(),
      startingPrice: machine?.startingPrice,
    });
  };

  const updateWorkerRef = useRef();
  updateWorkerRef.current = () => {
    if (!activeSeason) return;
    const now = Date.now();
    const gameDays = (now - activeSeason.startTime.toDate().getTime()) / MILISECONDS_IN_A_DAY;
    const minutes = (now - activeSeason.startTime.toDate().getTime()) / MILLISECONDS_PER_MINUTE;
    const fakePurchases = purchaseConfig[`${Math.floor(minutes)}`] || purchaseConfig['default'];

    gameRef.current?.events.emit('update-workers', {
      numberOfWorkers,
      balance: tokenBalance,
      basePrice: worker.basePrice,
      targetDailyPurchase: worker.targetDailyPurchase,
      pricePower: worker.pricePower,
      targetPrice: worker.targetPrice,
      dailyWarCount: 24 / (activeSeason?.warConfig?.warIntervalDurationInHours || 24),
      maxPerBatch: worker.maxPerBatch,
      totalSold: activeSeason?.workerSold + fakePurchases.numberOfWorkerFakePurchases,
      days: gameDays,
      dailyReward: worker.dailyReward,
      networthBase: worker.networthBase,
      networthPerDay: worker.networthPerDay,
      isTrackingSales: listeningWorker,
      started: now > activeSeason.startTime.toDate().getTime(),
      startingPrice: worker?.startingPrice,
    });
  };

  const updateBuildingRef = useRef();
  updateBuildingRef.current = () => {
    if (!activeSeason) return;
    const now = Date.now();
    const gameDays = (now - activeSeason.startTime.toDate().getTime()) / MILISECONDS_IN_A_DAY;
    const minutes = (now - activeSeason.startTime.toDate().getTime()) / MILLISECONDS_PER_MINUTE;
    const fakePurchases = purchaseConfig[`${Math.floor(minutes)}`] || purchaseConfig['default'];

    gameRef.current?.events.emit('update-buildings', {
      numberOfBuildings,
      basePrice: building.basePrice,
      maxPerBatch: building.maxPerBatch,
      targetDailyPurchase: building.targetDailyPurchase,
      pricePower: building.pricePower,
      targetPrice: building.targetPrice,
      totalSold: activeSeason?.buildingSold + fakePurchases.numberOfBuildingFakePurchases,
      days: gameDays,
      networthBase: building.networthBase,
      networthPerDay: building.networthPerDay,
      isTrackingSales: listeningBuilding,
      started: now > activeSeason.startTime.toDate().getTime(),
      startingPrice: building?.startingPrice,
    });
  };

  const updateThugRef = useRef();
  updateThugRef.current = () => {
    if (!activeSeason) return;
    const now = Date.now();
    const gameDays = (now - activeSeason.startTime.toDate().getTime()) / MILISECONDS_IN_A_DAY;
    const minutes = (now - activeSeason.startTime.toDate().getTime()) / MILLISECONDS_PER_MINUTE;
    const fakePurchases = purchaseConfig[`${Math.floor(minutes)}`] || purchaseConfig['default'];

    gameRef.current?.events.emit('update-thugs', {
      numberOfThugs,
      basePrice: thug.basePrice,
      maxPerBatch: thug.maxPerBatch,
      targetDailyPurchase: thug.targetDailyPurchase,
      pricePower: thug.pricePower,
      targetPrice: thug.targetPrice,
      totalSold: activeSeason?.thugSold + fakePurchases.numberOfThugFakePurchases,
      days: gameDays,
      dailyReward: thug.dailyReward,
      networthBase: thug.networthBase,
      networthPerDay: thug.networthPerDay,
      isTrackingSales: listeningThug,
      started: now > activeSeason.startTime.toDate().getTime(),
      startingPrice: thug?.startingPrice,
    });
  };

  const getMachineNumbersRef = useRef();
  getMachineNumbersRef.current = () => {
    gameRef.current?.events.emit('update-machine-numbers', {
      numberOfMachines: gamePlay?.numberOfMachines,
      gangwarMinNumberOfMachines: activeSeason?.warConfig?.gangwarMinNumberOfMachines,
    });
  };

  const updateXTokenBalanceRef = useRef();
  updateXTokenBalanceRef.current = () => {
    gameRef.current.events.emit('update-xtoken-balance', { balance: xTokenBalance });
  };

  const updatePoorTokenBalanceRef = useRef();
  updatePoorTokenBalanceRef.current = () => {
    if (!gamePlay || !activeSeason) return;
    const { thug } = activeSeason;
    const { startPoorTokenCountingTime, numberOfThugs, poorTokenBalance } = gamePlay;

    const now = Date.now();
    const startTime = startPoorTokenCountingTime.toDate().getTime();

    const diffInDays = (now - startTime) / MILISECONDS_IN_A_DAY;
    if (diffInDays < 0) return;

    const balance = poorTokenBalance + numberOfThugs * thug.dailyReward * diffInDays;

    gameRef.current?.events.emit('update-poor-token-balance', { balance });
  };

  const getRewardRef = useRef();
  getRewardRef.current = async () => {
    try {
      const res = await getReward();
      gameRef.current?.events?.emit('update-end-game-reward', res.data);
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      gameRef.current?.events?.emit('update-end-game-reward', {
        ethReward: 0,
        tokenReward: 0,
        blastGoldReward: 0,
        ethDistributed: 0,
        tokenDistributed: 0,
        blastGoldDistributed: 0,
      });
    }
  };

  const loadWalletAirdropRef = useRef();
  loadWalletAirdropRef.current = async () => {
    try {
      const res = await getAirdropWalletList();
      gameRef.current?.events.emit('update-airdrop-data', { data: res.data, status: activeSeason?.status });
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const loadStarterPackAvailable = useRef();
  loadStarterPackAvailable.current = async ({ packId }) => {
    if (!activeSeason?.starterPackConfig?.[packId]) {
      gameRef.current.events.emit('update-starter-pack-available', {
        starterPackAvailable: false,
      });
      return;
    }
    gameRef.current.events.emit('update-starter-pack-available', {
      starterPackAvailable:
        activeSeason?.starterPackConfig[packId].status === 'active' &&
        packsPurchased[packId] < activeSeason.starterPackConfig[packId].maxPerUser &&
        !starterPackCountdown.isEnded,
    });
  };

  useEffect(() => {
    if (profile && gamePlay && activeSeasonId && !loaded && !!userWallet) {
      setLoaded(true);
    }
  }, [loaded, profile, gamePlay, activeSeasonId, userWallet]);

  useEffect(() => {
    if (!gameLoaded.current) {
      gameLoaded.current = true;

      const config = {
        type: Phaser.CANVAS,
        width,
        height,
        pixelArt: true,
        transparent: true,
        parent: 'game-container',
        fps: {
          target: 60,
        },
        scale: {
          mode: Phaser.Scale.FIT,
        },
        scene: [LoadingScene, TutorialScene, MainScene, AuctionScene],
        debug: false,
        audio: {
          mute: sound !== 'on',
        },
        plugins: {
          global: [
            {
              key: 'rexCircleMaskImagePlugin',
              plugin: CircleMaskImagePlugin,
              start: true,
            },
          ],
        },
      };

      const game = new Phaser.Game(config);
      game.sound.setMute(sound !== 'on');

      game.events.on('hide-bg', () => {
        logAnalyticsEvent('game_load', { loading_duration: (Date.now() - startLoadingTime) / 1000 });
        setShowBg(false);
      });

      gameRef.current = game;
    }

    if (loaded && !gameEventListened.current) {
      gameEventListened.current = true;

      setupSimulatorGameListener(gameRef.current);

      gameRef.current?.events.on('check-user-completed-tutorial', () => {
        const completed = profile.completedTutorial;
        gameRef.current?.events.emit('update-user-completed-tutorial', { completed });
      });

      gameRef.current?.events.on('request-spin-rewards', () => updateSpinRewardRef.current());

      gameRef.current?.events.on('export-wallet', exportWallet);
      gameRef.current?.events.on('log-out', logout);
      gameRef.current?.events.on('toggle-game-sound', toggleSound);

      gameRef.current?.events.on('request-game-ended-status', () => {
        if (isEnded) gameRef.current?.events.emit('game-ended');
      });
      gameRef.current?.events.on('request-user-away-reward', async () => {
        try {
          if (
            !profile ||
            !gamePlay?.startXTokenCountingTime ||
            (gamePlay && numberOfMachines === 0) ||
            activeSeason?.status !== 'open'
          ) {
            gameRef.current?.events.emit('update-user-away-reward', {
              noShow: true,
            });
            return;
          }
          const lastUnixTimeSeenWarResult = gamePlay?.lastTimeSeenWarResult
            ? gamePlay?.lastTimeSeenWarResult.toDate().getTime()
            : 0;
          const res = await getLatestWar();
          const latestWar = res.data?.latestWar;
          const latestWarUnixTime = latestWar?.createdAt;
          const showWarPopup = latestWarUnixTime && lastUnixTimeSeenWarResult < latestWarUnixTime;

          let startTime = gamePlay.startXTokenCountingTime.toDate().getTime();
          if (profile.lastOnlineTime) {
            startTime = profile.lastOnlineTime.toDate().getTime();
          }

          const now = Date.now();
          const diffInDays = (now - startTime) / MILISECONDS_IN_A_DAY;
          const claimableReward = Math.abs(diffInDays * dailyXToken);

          const networthPerDay =
            numberOfMachines * machine.networthPerDay +
            numberOfBuildings * building.networthPerDay +
            numberOfWorkers * worker.networthPerDay;
          const generatedNetworth = Math.floor(diffInDays * networthPerDay);
          gameRef.current?.events.emit('update-user-away-reward', {
            showWarPopup,
            claimableReward,
            generatedNetworth,
          });
          setOnlineListener(true);
        } catch (err) {
          console.error(err);
          Sentry.captureException(err);
        }
      });
      gameRef.current?.events.on('request-app-version', () => {
        gameRef.current.events.emit('update-app-version', appVersion);
      });
      gameRef.current?.events.on('request-profile', () => {
        gameRef.current.events.emit('update-profile', {
          username,
          address,
          socials,
          avatarURL: avatarURL_big ?? avatarURL,
        });
      });
      gameRef.current?.events.on('request-season', () => {
        const { name } = activeSeason || {};
        gameRef.current.events.emit('update-season', {
          name,
          prizePoolEth,
          prizePoolToken,
          blastGoldReward,
          rewardPercentNextPayout: midSeasonSnapshotTaken
            ? 1 - prizePoolConfig.midSeasonPrizePoolPercentage
            : prizePoolConfig.midSeasonPrizePoolPercentage,
          xUPool: xUReward,
          isEnded,
        });
      });
      gameRef.current?.events.on('open-leaderboard-modal', () => {
        setLeaderboardModalOpen(true);
        queryClient.invalidateQueries({ queryKey: [QueryKeys.Leaderboard] });
        const { name } = activeSeason || {};
        gameRef.current.events.emit('update-season', {
          name,
          prizePoolEth,
          prizePoolToken,
          blastGoldReward,
          xUPool: xUReward,
          isEnded,
          rewardPercentNextPayout: midSeasonSnapshotTaken
            ? 1 - prizePoolConfig.midSeasonPrizePoolPercentage
            : prizePoolConfig.midSeasonPrizePoolPercentage,
        });
      });
      gameRef.current?.events.on('close-leaderboard-modal', () => {
        setLeaderboardModalOpen(false);
      });

      gameRef.current?.events.on('request-leaderboard-list', async ({ pages, limit }) => {
        try {
          const [reputationRes, raidPointRes, uPointRes] = await Promise.all([
            getLeaderboard({ page: pages.reputation, limit }),
            getRaidPointLeaderboard({ page: pages.raidPoints, limit }),
            getUPointLeaderboard({ page: pages.uPoints, limit }),
          ]);
          gameRef?.current?.events.emit('update-leaderboard-list', {
            totalPages: {
              reputation: reputationRes.data.totalPages,
              raidPoints: raidPointRes.data.totalPages,
              uPoints: uPointRes.data.totalPages,
            },
            users: {
              reputation: reputationRes.data.users,
              raidPoints: raidPointRes.data.users,
              uPoints: uPointRes.data.users,
            },
            userRecord: {
              reputation: reputationRes.data.userRecord,
              raidPoints: raidPointRes.data.userRecord,
              uPoints: uPointRes.data.userRecord,
            },
          });
          gameRef?.current?.events.emit('update-portfolio-rewards', {
            rankEthReward: reputationRes.data.userRecord.ethReward + raidPointRes.data.userRecord.ethReward,
            rankTokenReward: reputationRes.data.userRecord.tokenReward + raidPointRes.data.userRecord.tokenReward,
            xU: uPointRes.data.userRecord.xUReward,
            blastGold: uPointRes.data.userRecord.blastGoldReward,
          });
        } catch (err) {
          console.error(err);
          Sentry.captureException(err);
        }
      });

      gameRef.current?.events.on('request-balances', () => {
        gameRef.current.events.emit('update-balances', { ETHBalance, tokenBalance });
      });

      gameRef.current?.events.on('request-deposit-code', () => {
        gameRef.current.events.emit('update-deposit-code', profile.code);
      });
      gameRef.current?.events.on('simulator-request-deposit-code', () => {
        gameRef.current.events.emit('simulator-update-deposit-code', profile.code);
      });
      gameRef.current?.events.on('request-eth-balance', async () => {
        try {
          const newBalance = await getETHBalance(address);
          console.log({ ETHBalance, newBalance });
          if (newBalance !== ETHBalance) {
            reloadBalance();
          }
          gameRef.current.events.emit('update-eth-balance', { address, ETHBalance: newBalance });
        } catch (err) {
          console.error(err);
          Sentry.captureException(err);
        }
      });
      gameRef.current?.events.on('refresh-eth-balance', () => {
        reloadBalance();
      });

      gameRef.current?.events.on('request-balances-for-withdraw', () => {
        gameRef.current.events.emit('update-balances-for-withdraw', {
          NFTBalance: numberOfMachines,
          ETHBalance,
          tokenBalance,
        });
      });
      gameRef.current?.events.on('request-wallet-nft-balance', () => {
        getNFTBalance(address)
          .then((balance) => {
            gameRef.current.events.emit('update-wallet-nft-balance', { balance, numberOfMachines });
          })
          .catch((err) => {
            console.error(err);
            Sentry.captureException(err);
          });
      });

      gameRef.current?.events.on('request-wallet-nft-unstaked', () => {
        getNFTBalance(address)
          .then((balance) => {
            gameRef.current.events.emit('update-wallet-nft-unstaked', { balance });
          })
          .catch((err) => {
            console.error(err);
            Sentry.captureException(err);
          });
      });

      gameRef.current?.events.on('request-rank', () => {
        getRank()
          .then((res) => gameRef.current.events.emit('update-rank', { rank: res.data.rank }))
          .catch((err) => {
            console.error(err);
            Sentry.captureException(err);
          });
      });

      gameRef.current?.events.on('request-networth', () => calculateNetworthRef?.current?.());

      gameRef.current?.events.on('request-claim-time', () => {
        gameRef.current.events.emit('update-claim-time', {
          claimGapInSeconds: activeSeason.claimGapInSeconds,
          lastClaimTime: gamePlay.lastClaimTime.toDate().getTime(),
          active: gamePlay.active,
        });
      });

      gameRef.current?.events.on('request-claimable-reward', () => calculateClaimableRewardRef.current?.());
      gameRef.current?.events.on('request-xtoken-balance', () => updateXTokenBalanceRef.current?.());
      gameRef.current?.events.on('request-u-point-reward', () =>
        gameRef.current?.events.emit('update-u-point-reward', { uPointReward })
      );
      gameRef.current?.events.on('check-game-ended', () => checkGameEndRef.current?.());

      gameRef.current?.events.on('request-claimable-status', () => {
        const now = Date.now();
        const endUnixTime = activeSeason.estimatedEndTime.toDate().getTime();
        const nextClaimTime = gamePlay.lastClaimTime.toDate().getTime() + activeSeason.claimGapInSeconds * 1000;
        const claimable = now > nextClaimTime && now < endUnixTime;
        gameRef.current.events.emit('update-claimable-status', { claimable, active: gamePlay.active });
      });

      gameRef.current?.events.on('request-active-status', () => {
        gameRef.current.events.emit('update-active-status', { active: gamePlay.active });
      });

      gameRef.current?.events.on('request-war-history', () => {
        getWarHistory()
          .then((res) => gameRef.current.events.emit('update-war-history', res.data))
          .catch((err) => {
            console.error(err);
            Sentry.captureException(err);
          });
      });

      gameRef.current?.events.on('request-war-die-chance', () => {
        gameRef.current.events.emit('update-war-die-chance', { dieChance: activeSeason.warConfig.dieChance });
      });

      gameRef.current?.events.on('request-next-war-time', () => {
        getNextWarSnapshotUnixTime()
          .then((res) => {
            gameRef.current.events.emit('update-next-war-time', { time: res.data.time });
          })
          .catch((err) => {
            console.error(err);
            Sentry.captureException(err);
          });
      });

      gameRef.current?.events.on('request-next-spin-increment-time', () => {
        getNextSpinIncrementUnixTime()
          .then((res) => {
            gameRef.current.events.emit('update-next-spin-increment-time', { time: res.data.time });
          })
          .catch((err) => {
            console.error(err);
            Sentry.captureException(err);
          });
      });
      gameRef.current?.events.on('withdraw-token', ({ amount, address }) => {
        transfer({ amount, address, tokenType: 'GREED' });
      });
      gameRef.current?.events.on('withdraw-eth', ({ amount, address }) => {
        transfer({ amount, address, tokenType: 'ETH' });
      });
      gameRef.current?.events.on('withdraw-nft', ({ amount, address }) => {
        transfer({ amount, address, tokenType: 'NFT' });
      });
      gameRef.current?.events.on('deposit-nft', ({ amount }) => {
        stake(amount);
      });
      gameRef.current?.events.on('swap', async ({ tokenSwap, amount }) => {
        try {
          console.log({ tokenSwap, amount });
          gameRef.current.events.emit('swap-started', { amount, txnHash: '' });
          const func = tokenSwap === 'eth' ? swapEthToToken : swapTokenToEth;
          const { receipt, receiveAmount } = await func(amount);
          if (receipt.status === 1) {
            gameRef.current.events.emit('swap-completed', {
              txnHash: receipt.transactionHash,
              amount: receiveAmount,
              token: tokenSwap === 'eth' ? '$GREED' : 'ETH',
              description: tokenSwap === 'eth' ? 'Swap ETH to $GREED completed' : 'Swap $GREED to ETH completed',
            });
            reloadBalance();
          }
        } catch (err) {
          const { message, code } = handleError(err);
          gameRef.current?.events.emit('swap-completed', {
            status: 'failed',
            code,
            message,
          });
        }
      });

      gameRef.current?.events.on('claim', async () => {
        let amount;
        try {
          const res = await claimToken();
          amount = res.data.claimedAmount;
        } catch (err) {
          console.error(err);
          Sentry.captureException(err);
        }
        gameRef.current?.events.emit('claim-completed', { amount });
      });

      gameRef.current?.events.on('request-gas-mint', () => {
        gameRef.current.events.emit('update-gas-mint', { gas: estimatedGas?.game?.buyGangster });
      });

      gameRef.current?.events.on('request-gas-swap-eth-fiat', () => {
        gameRef.current.events.emit('update-gas-swap-eth-fiat', { gas: estimatedGas?.swap?.swapEthToToken });
      });

      gameRef.current?.events.on('request-gas-buy-goon', () => {
        gameRef.current.events.emit('update-gas-buy-goon', { gas: estimatedGas?.game?.buyAsset });
      });

      gameRef.current?.events.on('request-gas-upgrade-safehouse', () => {
        gameRef.current.events.emit('update-gas-upgrade-safehouse', { gas: estimatedGas?.game?.buyAsset });
      });

      gameRef.current?.events.on('upgrade-safehouse', async ({ quantity, token }) => {
        try {
          const txnHash = await buyBuilding({ quantity, token });
          gameRef.current?.events.emit('upgrade-safehouse-completed', { txnHash, amount: quantity });
        } catch (err) {
          const { message, code } = handleError(err);
          gameRef.current?.events.emit('upgrade-safehouse-completed', {
            status: 'failed',
            code,
            message,
          });
        }
      });

      gameRef.current?.events.on('request-buildings', () => updateBuildingRef.current?.());

      gameRef.current?.events.on('request-thugs', () => updateThugRef.current?.());

      gameRef.current?.events.on('request-airdrop-data', () => loadWalletAirdropRef.current?.());

      gameRef.current?.events.on('buy-thug', async ({ amount }) => {
        try {
          await buyThugsWithPoorToken({ amount });
          gameRef.current?.events.emit('buy-thug-completed', { amount });
        } catch (err) {
          const { message, code } = handleError(err);
          gameRef.current?.events.emit('buy-thug-completed', {
            status: 'failed',
            code,
            message,
          });
        }
      });

      gameRef.current?.events.on('request-poor-token-balance', () => updatePoorTokenBalanceRef.current?.());

      gameRef.current?.events.on('request-machine-numbers', () => getMachineNumbersRef.current?.());

      gameRef.current?.events.on('buy-goon', async ({ quantity, token }) => {
        try {
          const txnHash = await buyWorker({ quantity, token });
          gameRef.current?.events.emit('buy-goon-completed', { txnHash, amount: quantity });
        } catch (err) {
          const { message, code } = handleError(err);
          gameRef.current?.events.emit('buy-goon-completed', {
            status: 'failed',
            code,
            message,
          });
        }
      });

      gameRef.current?.events.on('start-spin', async () => {
        try {
          await initDailySpin();
        } catch (err) {
          if (err.message === 'Already spin today') {
            gameRef.current?.events.emit('spin-error', {
              code: '4001',
              message: err.message,
            });
          } else {
            const { message, code } = handleError(err);
            gameRef.current?.events.emit('spin-error', {
              code,
              message,
            });
          }
        }
      });

      gameRef.current?.events.on('buy-gangster', async ({ quantity, mintFunction }) => {
        try {
          const txnHash = await buyGangster(quantity, mintFunction);
          gameRef.current?.events.emit('buy-gangster-completed', { txnHash, amount: quantity });
        } catch (err) {
          const { message, code } = handleError(err);
          gameRef.current?.events.emit('buy-gangster-completed', {
            status: 'failed',
            code,
            message,
          });
        }
      });

      gameRef.current?.events.on('upgrade-gangsters', async ({ amount }) => {
        try {
          await upgradeUserMachines();
          gameRef.current?.events.emit('upgrade-gangsters-completed', {
            message: `Your ${amount} gangster${amount > 1 ? 's' : ''} ${amount > 1 ? 'are' : 'is'} upgraded`,
            title: `Upgrade gangster${amount > 1 ? 's' : ''} successfully`,
          });
        } catch (err) {
          gameRef.current?.events.emit('upgrade-gangsters-completed', {
            status: 'failed',
            code: 4001,
            message: err.message,
            action: err.message === 'You have no gangster' ? 'Please buy gangster first' : '',
          });
        }
      });

      gameRef.current?.events.on('war-upgrade-start', async ({ pistolQuantity, shieldQuantity, token }) => {
        try {
          const txnHash = await buyWarUpgrades({ type: 'pistol', quantity: pistolQuantity, token });
          const txnHash2 = await buyWarUpgrades({ type: 'shield', quantity: shieldQuantity, token });
          gameRef.current?.events.emit('war-upgrade-completed', {
            txnHash,
            txnHash2,
            pistolQuantity,
            shieldQuantity,
          });
        } catch (err) {
          const { message, code } = handleError(err);
          gameRef.current?.events.emit('war-upgrade-completed', {
            status: 'failed',
            code,
            message,
          });
        }
      });

      gameRef.current?.events.on('request-workers', () => updateWorkerRef.current?.());

      gameRef.current?.events.on('request-machines', () => updateMachineRef.current?.());

      gameRef.current?.events.on('request-workers-machines', () => {
        gameRef.current?.events.emit('update-workers-machines', {
          numberOfWorkers,
          numberOfMachines,
          numberOfThugs,
        });
      });

      gameRef.current?.events.on('request-portfolio', async () => {
        const machineValue = numberOfMachines * parseFloat(nftPrice);
        gameRef.current?.events?.emit('update-portfolio', {
          address,
          ETHBalance,
          tokenBalance,
          tokenPrice,
          numberOfMachines,
          machineValue,
          blastPointReward,
        });
      });
      gameRef.current?.events.on('request-market-data', () => {
        gameRef.current?.events.emit('update-market-data', { tokenPrice, ethPriceInUsd });
      });
      gameRef.current?.events.on('request-statistic', () => {
        getRank().then((res) => {
          const { rank, totalPlayers } = res.data;
          gameRef.current?.events.emit('update-statistic', {
            rank,
            totalPlayers,
            numberOfWorkers,
            numberOfMachines,
            numberOfBuildings,
            numberOfThugs,
            raidPoint,
            numberOfPistols,
            numberOfShields,
          });
        });
      });

      gameRef.current?.events.on('check-user-loaded', () => {
        gameRef.current?.events.emit('user-info-loaded');
      });

      gameRef.current?.events.on('update-last-time-seen-war-result', () => {
        updateLastTimeSeenGangWarResult().catch((err) => {
          console.error(err);
          Sentry.captureException(err);
        });
      });

      gameRef.current?.events.on('request-prize-pool-info', () => {
        gameRef.current?.events.emit('update-prize-pool-info', {
          prizePoolConfig,
          xUPool: xUReward,
          blastGoldReward,
        });
      });

      gameRef.current?.events.on('request-game-play', () => {
        gameRef.current?.events.emit('update-game-play', {
          numberOfMachines,
          numberOfAvailableMachines,
          numberOfWorkers,
          numberOfBuildings,
          totalPistolBonus: numberOfPistols * pistol.attackPoint,
          totalShieldBonus: numberOfShields * shield.defencePoint,
          ...warDeployment,
          ...activeSeason.warConfig,
          warNext: warState?.next || 1,
          latestWarChain,
        });
      });

      gameRef.current?.events.on('request-war-upgrades', () => {
        gameRef.current?.events.emit('update-war-upgrades', {
          numberOfPistols,
          numberOfShields,
          balance: tokenBalance,
          pistolPrice: pistol.price,
          pistolMaxPerBatch: pistol.maxPerBatch,
          pistolAttackPoint: pistol.attackPoint,
          shieldPrice: shield.price,
          shieldMaxPerBatch: shield.maxPerBatch,
          shieldDefencePoint: shield.defencePoint,
        });
      });

      gameRef.current?.events.on('update-war-machines', (data) => {
        updateUserWarDeployment(data)
          .then(() => gameRef.current?.events.emit('update-war-machines-completed', data))
          .then(reloadUserWarDeployment)
          .catch((err) => {
            console.error(err);
            Sentry.captureException(err);
            gameRef.current?.events.emit('update-war-machines-error');
          });
      });

      gameRef.current?.events.on('convert-eth-input-to-token', ({ amount }) => {
        convertEthInputToToken(amount)
          .then((result) =>
            gameRef.current?.events.emit('convert-eth-input-to-token-result', {
              amount: result.amount,
              tradingFee: result.tradingFee,
              tradingFeeInUSD: result.tradingFeeInUSD,
            })
          )
          .catch((err) => {
            gameRef.current?.events.emit('swap-error');
            console.error(err);
            Sentry.captureException(err);
          });
      });

      // gameRef.current?.events.on('update-war-attack', (data) => {
      //   const { attackUserId } = data;

      //   updateUserWarAttack({ attackUserId })
      //     .then(reloadUserWarDeployment)
      //     .catch((err) => {
      //       console.error(err);
      //       Sentry.captureException(err);
      //     })
      //     .finally(() => {
      //       gameRef.current?.events.emit('update-war-attack-completed');
      //     });
      // });

      gameRef.current?.events.on('convert-eth-output-to-token', ({ amount }) => {
        convertEthOutputToToken(amount)
          .then((result) =>
            gameRef.current?.events.emit('convert-eth-output-to-token-result', {
              amount: result.amount,
              tradingFee: result.tradingFee,
              tradingFeeInUSD: result.tradingFeeInUSD,
            })
          )
          .catch((err) => {
            gameRef.current?.events.emit('swap-error');
            console.error(err);
            Sentry.captureException(err);
            if (err.message.includes('Not enough')) {
              enqueueSnackbar(err.message, { variant: 'error' });
            }
          });
      });

      gameRef.current?.events.on('request-war-config', () => {
        const { tokenRewardPerEarner, earningStealPercent, machinePercentLost, stolenNetworthPercent, sizeAdjustment } =
          activeSeason.warConfig;
        gameRef.current?.events.emit('update-war-config', {
          tokenRewardPerEarner,
          earningStealPercent,
          machinePercentLost,
          stolenNetworthPercent,
          sizeAdjustment,
        });
      });

      gameRef.current?.events.on('request-user-list-to-attack', ({ page, limit, search, orderBy, sortDirection }) => {
        getUserListToAttack({ page, limit, search, orderBy, sortDirection })
          .then((res) => {
            const { totalDocs, docs } = res.data;
            const totalPages = Math.ceil(totalDocs / limit);
            gameRef.current?.events.emit('update-user-list-to-attack', { totalPages, users: docs });
          })
          .catch((err) => {
            console.error(err);
            Sentry.captureException(err);
            gameRef.current?.events.emit('update-user-list-to-attack-failed');
          });
      });

      gameRef.current?.events.on('request-spin-history', ({ page, limit }) => {
        getSpinTxns({ page, limit })
          .then((res) => {
            const { totalPages, items } = res.data;
            gameRef.current?.events.emit('update-spin-history', {
              totalPages,
              txns: items.map((item) => ({
                createdAt: moment(new Date(item.createdAt)).format('DD/MM, HH:mm'),
                reward: item.reward,
              })),
            });
          })
          .catch((err) => {
            gameRef.current?.events.emit('update-spin-history-error');
            console.error(err);
            Sentry.captureException(err);
          });
      });

      gameRef.current?.events.on('convert-token-input-to-eth', ({ amount }) => {
        convertTokenInputToEth(amount)
          .then((result) =>
            gameRef.current?.events.emit('convert-token-input-to-eth-result', {
              amount: result.amount,
              tradingFee: result.tradingFee,
              tradingFeeInUSD: result.tradingFeeInUSD,
            })
          )
          .catch((err) => {
            gameRef.current?.events.emit('swap-error');
            console.error(err);
            Sentry.captureException(err);
          });
      });

      gameRef.current?.events.on('request-user-to-attack-detail', ({ userId }) => {
        getUserDetailToAttack(userId)
          .then((res) => {
            const { user, gamePlay, warResults, lastRaidPower } = res.data;
            gameRef.current?.events.emit('update-user-to-attack-detail', {
              user,
              gamePlay,
              warResults,
              lastRaidPower,
            });
          })
          .catch((err) => {
            console.error(err);
            Sentry.captureException(err);
          });
      });
      gameRef.current?.events.on('convert-token-output-to-eth', ({ amount }) => {
        convertTokenOutputToEth(amount)
          .then((result) =>
            gameRef.current?.events.emit('convert-token-output-to-eth-result', {
              amount: result.amount,
              tradingFee: result.tradingFee,
              tradingFeeInUSD: result.tradingFeeInUSD,
            })
          )
          .catch((err) => {
            gameRef.current?.events.emit('swap-error');
            console.error(err);
            Sentry.captureException(err);
            if (err.message.includes('Not enough')) {
              enqueueSnackbar(err.message, { variant: 'error' });
            }
          });
      });

      gameRef.current?.events.on('request-war-history-detail', ({ warSnapshotId, warResultId }) => {
        getWarHistoryDetail({ warSnapshotId, warResultId })
          .then((res) => {
            gameRef.current?.events.emit('update-war-history-detail', res.data);
          })
          .catch((err) => {
            console.error(err);
            Sentry.captureException(err);
          });
      });

      gameRef.current?.events.on('request-war-history-latest', () => {
        getLatestWarResult()
          .then((res) => {
            gameRef.current?.events.emit('update-war-history-latest', res.data);
          })
          .catch((err) => {
            console.error(err);
            Sentry.captureException(err);
          });
      });

      gameRef.current?.events.on('enable-worker-sales-tracking', () => {
        enableWorkerSalesTracking();
      });

      gameRef.current?.events.on('enable-building-sales-tracking', () => {
        enableBuildingSalesTracking();
      });

      gameRef.current?.events.on('enable-machine-sales-tracking', () => {
        enableMachineSalesTracking();
      });

      gameRef.current?.events.on('enable-thug-sales-tracking', () => {
        enableThugSalesTracking();
      });

      gameRef.current?.events.on('disable-worker-sales-tracking', () => {
        disableWorkerSalesTracking();
      });

      gameRef.current?.events.on('disable-building-sales-tracking', () => {
        disableBuildingSalesTracking();
      });

      gameRef.current?.events.on('disable-machine-sales-tracking', () => {
        disableMachineSalesTracking();
      });

      gameRef.current?.events.on('disable-thug-sales-tracking', () => {
        disableThugSalesTracking();
      });

      gameRef.current?.events.on('request-goon-price', ({ timeMode }) => {
        getWorkerPrices({ timeMode })
          .then((res) => gameRef.current?.events.emit('update-goon-price', res.data))
          .catch((err) => {
            console.error(err);
            Sentry.captureException(err);
          });
      });

      gameRef.current?.events.on('request-house-price', ({ timeMode }) => {
        getBuildingPrices({ timeMode })
          .then((res) => gameRef.current?.events.emit('update-house-price', res.data))
          .catch((err) => {
            console.error(err);
            Sentry.captureException(err);
          });
      });

      gameRef.current?.events.on('request-gangster-price', ({ timeMode }) => {
        getMachinePrices({ timeMode })
          .then((res) => gameRef.current?.events.emit('update-gangster-price', res.data))
          .catch((err) => {
            console.error(err);
            Sentry.captureException(err);
          });
      });

      gameRef.current?.events.on('request-fee-percent', () => {
        getTotalFees()
          .then((res) => gameRef.current?.events.emit('update-fee-percent', { feePercent: res }))
          .catch((err) => {
            console.error(err);
            Sentry.captureException(err);
          });
      });

      gameRef.current?.events.on('request-badge-number', () => {
        gameRef.current?.events.emit('update-badge-number', {
          numberOfSpins: gamePlay.numberOfSpins,
        });
      });

      gameRef.current?.events.on('request-unread-messages', () => {
        gameRef.current?.events.emit('update-unread-messages', {
          numberOfUnreadMessages: gamePlay.numberOfUnreadMessages,
        });
      });

      gameRef.current?.events.on('request-spin-config', () => {
        gameRef.current?.events.emit('update-spin-config', {
          spinIncrementStep: activeSeason?.spinConfig?.spinIncrementStep,
          maxSpin: activeSeason?.spinConfig?.maxSpin,
        });
      });

      gameRef.current?.events.on('request-twitter-share-reward-template', () => {
        gameRef.current?.events.emit('update-twitter-share-reward-template', {
          template: templates.twitterShareReward,
        });
      });

      gameRef.current?.events.on('request-twitter-share-playtest-airdrop-template', () => {
        gameRef.current?.events.emit('update-twitter-share-playtest-airdrop-template', {
          template: templates.twitterSharePlaytestAirdrop,
        });
      });

      gameRef.current?.events.on('request-twitter-share-thug-airdrop-template', () => {
        gameRef.current?.events.emit('update-twitter-share-thug-airdrop-template', {
          template: templates.twitterShareThugAirdrop,
        });
      });

      gameRef.current?.events.on('claim-thug-airdrop', async ({ shared }) => {
        try {
          await claimThug({ shared });
          await loadWalletAirdropRef.current?.();
        } catch (err) {
          console.error(err);
          Sentry.captureException(err);
        }
        gameRef.current?.events.emit('claim-thug-airdrop-done');
      });

      gameRef.current?.events.on('request-airdrop-status', async () => {
        try {
          const res = await getAirdropClaimStatus();
          gameRef.current?.events.emit('update-playtest-data', res?.data);
        } catch (err) {
          console.error(err);
          Sentry.captureException(err);
        }
      });

      gameRef.current?.events.on('request-claimed-free-thugs', () => {
        gameRef.current?.events.emit('update-claimed-free-thugs', {
          claimedFreeThugs: gamePlay?.claimedFreeThugs,
        });
      });

      gameRef.current?.events.on('request-twitter-share-free-thugs-template', () => {
        gameRef.current?.events.emit('update-twitter-share-free-thugs-template', {
          template: templates.twitterShareFreeThug,
        });
      });

      gameRef.current?.events.on('request-number-of-free-thugs', () => {
        gameRef.current?.events.emit('update-number-of-free-thugs', {
          numberOfFreeThugs: activeSeason?.airdropConfig?.numberOfFreeThugs || 0,
        });
      });

      gameRef.current?.events.on('claim-free-thugs', async ({ shared }) => {
        try {
          await claimFreeThug({ shared });
        } catch (err) {
          console.error(err);
          Sentry.captureException(err);
        }
        gameRef.current?.events.emit('claim-free-thugs-done');
      });

      gameRef.current?.events.on('share-playtest', async () => {
        try {
          await shareAirdrop();
          const res = await getAirdropClaimStatus();
          if (res.data) {
            gameRef.current?.events.emit('update-playtest-data', res.data);
          }
        } catch (err) {
          console.error(err);
          Sentry.captureException(err);
        }
        gameRef.current?.events.emit('share-playtest-done');
      });

      gameRef.current?.events.on('claim-playtest', async () => {
        try {
          await claimAirdrop();
          const res = await getAirdropClaimStatus();
          if (res.data) {
            gameRef.current?.events.emit('update-playtest-data', res.data);
          }
        } catch (err) {
          console.error(err);
          Sentry.captureException(err);
        }
        gameRef.current?.events.emit('claim-playtest-done');
      });

      gameRef.current?.events.on('request-starter-pack-available', ({ packId }) => {
        loadStarterPackAvailable.current?.({ packId });
      });

      gameRef.current?.events.on('request-starter-pack-config', () => {
        gameRef.current.events.emit('update-starter-pack-config', activeSeason?.starterPackConfig);
      });

      gameRef.current?.events.on('open-starter-pack-modal', () => {
        setStarterPackModalOpen(true);
      });
      gameRef.current?.events.on('close-starter-pack-modal', () => {
        setStarterPackModalOpen(false);
      });

      gameRef.current?.events.on('buy-starter-pack', async ({ packId, goonsQuantity, gangstersQuantity }) => {
        try {
          const txnHash = await buyStarterPack({ packId });
          gameRef.current?.events.emit('buy-starter-pack-completed', {
            txnHash,
            goonsQuantity,
            gangstersQuantity,
          });
        } catch (err) {
          const { message, code } = handleError(err);
          gameRef.current?.events.emit('buy-starter-pack-completed', {
            status: 'failed',
            code,
            message,
          });
        }
      });

      gameRef.current?.events.on('request-referral-config', () => {
        gameRef.current.events.emit('update-referral-config', activeSeason?.referralConfig);
      });

      gameRef.current?.events.on('request-invite-code', () => {
        if (inviteCode) gameRef.current.events.emit('update-invite-code', { code: inviteCode });
      });

      gameRef.current?.events.on('request-referral-data', () => {
        gameRef.current.events.emit('update-referral-data', {
          referralTotalReward,
          referralTotalDiscount,
          referralProgramType,
          tokenPrice,
          ethPriceInUsd,
          tweetTemplate: templates.twitterShareReferralCode || '',
        });
      });

      gameRef.current?.events.on('request-referral-code', () => {
        gameRef.current?.events.emit('update-referral-code', { referralCode });
      });

      gameRef.current?.events.on('request-referral-discount', () => {
        getReferralDiscount()
          .then((res) => gameRef.current.events.emit('update-referral-discount', { discount: res.data.discount }))
          .catch((err) => {
            console.error(err);
            Sentry.captureException(err);
            gameRef.current.events.emit('update-referral-discount', { discount: 0 });
          });
      });

      gameRef.current?.events.on('apply-invite-code', ({ code }) => {
        applyInviteCode({ code })
          .then(() =>
            gameRef.current.events.emit('complete-apply-invite-code', { status: 'Success', message: 'Success' })
          )
          .catch((err) => {
            console.log('err', err);
            gameRef.current.events.emit('complete-apply-invite-code', { status: 'Error', message: err.message });
          });
      });

      gameRef.current?.events.on('unlink-twitter', async () => {
        try {
          await unlinkTwitter();
        } catch (err) {
          console.error(err);
          Sentry.captureException(err);
        }
        gameRef.current?.events.emit('twitter-done');
      });

      gameRef.current.events?.on('link-twitter', async () => {
        try {
          const res0 = await getXCsrfToken();
          localStorage.setItem('CSRF_TOKEN', res0.data.csrfToken);
          const res = await getOauthRequestToken();
          const { oauth_token } = res.data;
          const url = `https://api.twitter.com/oauth/authenticate?oauth_token=${oauth_token}`;
          window.location.href = url;
        } catch (err) {
          console.error(err);
          Sentry.captureException(err);
        }
        gameRef.current?.events.emit('twitter-done');
      });

      gameRef.current.events?.on('request-twitter-verified', () => {
        gameRef.current.events?.emit('update-twitter-verified', { twitterVerified: !!twitterVerified });
      });

      // auction scene listeners
      gameRef.current.events.on('get-auction-items', async () => {
        try {
          const res = await getAuctionItems();
          gameRef.current.events.emit('load-auction-items', res.data);
        } catch (err) {
          console.error(err);
          Sentry.captureException(err);
        }
      });

      gameRef.current.events.on('get-auction-end-time', async () => {
        try {
          const res = await getAuctionEndTime();
          gameRef.current.events.emit('load-auction-end-time', res.data.endTimeUnix);
        } catch (err) {
          console.error(err);
          Sentry.captureException(err);
        }
      });

      gameRef.current.events.on('get-next-auction-prize', () => {
        updateNextAuctionPrize.current?.();
      });

      gameRef.current.events.on('create-bidding', async (data) => {
        try {
          await createBidding(data);
          gameRef.current.events.emit('create-bidding-success');
        } catch (err) {
          console.error(err);
          Sentry.captureException(err);
          gameRef.current.events.emit('create-bidding-fail', err.message);
        }
      });

      gameRef.current.events.on('get-bidding-history', async () => {
        try {
          const res = await getBiddingHistory();
          gameRef.current.events.emit('load-bidding-history', res.data);
        } catch (err) {
          console.error(err);
          Sentry.captureException(err);
        }
      });

      gameRef.current.events.on('get-auction-item-bidding-history', async ({ subId }) => {
        try {
          const cachedData = cachedRef.current.auctionItemHistory?.[subId];
          let refetch = !cachedData;
          if (cachedData) {
            const now = Date.now();
            const diff = now - cachedData.time;
            if (diff > cacheTime) {
              refetch = true;
            }
          }
          if (refetch) {
            const res = await getAuctionItemHistory({ subId });
            cachedRef.current.auctionItemHistory[subId] = { data: res.data, time: Date.now() };
            gameRef.current.events.emit('load-auction-item-bidding-history', res.data);
          } else {
            gameRef.current.events.emit('load-auction-item-bidding-history', cachedData.data);
          }
        } catch (err) {
          console.error(err);
          Sentry.captureException(err);
        }
      });

      gameRef.current.events.on('get-auction-item-bidding-history-detail', async ({ subId, recordId }) => {
        try {
          const cachedData = cachedRef.current.auctionItemHistoryDetail[subId]?.[recordId];
          let refetch = !cachedData;
          if (cachedData) {
            const now = Date.now();
            const diff = now - cachedData.time;
            if (diff > cacheTime) {
              refetch = true;
            }
          }
          if (refetch) {
            const res = await getAuctionItemHistoryDetail({ subId, recordId });
            cachedRef.current.auctionItemHistoryDetail[subId] = { [recordId]: { data: res.data, time: Date.now() } };
            gameRef.current.events.emit('load-auction-item-bidding-history-detail', res.data);
          } else {
            gameRef.current.events.emit('load-auction-item-bidding-history-detail', cachedData.data);
          }
        } catch (err) {
          console.error(err);
          Sentry.captureException(err);
        }
      });

      gameRef.current.events.on('link-wagmi-wallet', () => {
        const connector = connectors.find((item) => item.name === 'MetaMask');
        disconnect(
          { connector },
          {
            onSuccess: () => {
              connect(
                { connector },
                {
                  onSuccess: async (data) => {
                    console.log('connect success', data);
                    const account = data.accounts[0];
                    if (!account) throw new Error('No address');
                    gameRef.current.events.emit('signing-wagmi-wallet');
                    try {
                      await signMessage({ account: account.toLowerCase() });
                      loadWalletAirdropRef.current?.();
                      disconnect({ connector });
                      gameRef.current.events.emit('link-wagmi-wallet-success');
                    } catch (err) {
                      console.error(err.message);
                      gameRef.current.events.emit('link-wagmi-wallet-error');
                    }
                  },
                  onError: async (err) => {
                    console.error(err);
                    let message = 'Something is wrong';
                    if (err.message.includes("Request of type 'wallet_requestPermissions' already pending")) {
                      message = 'Please close Metamask popup and try again';
                    }
                    gameRef.current.events.emit('link-wagmi-wallet-error', { message });
                  },
                }
              );
            },
          }
        );
      });

      gameRef.current.events.on('request-user-rewards', async () => {
        try {
          const res = await getUserRewards();
          gameRef.current.events.emit('update-user-rewards', res.data);
        } catch (err) {
          console.error(err);
          Sentry.captureException(err);
        }
      });

      // auth listeners
      gameRef.current.events?.on('request-end-game-reward', () => {
        getRewardRef.current?.();
      });

      gameRef.current?.events.on('request-auth', () => {
        gameRef.current?.events.emit('update-auth', { uid: profile.id });
      });

      gameRef.current?.events.emit('user-info-loaded');

      return () => {
        try {
          // gameRef.current?.scene.destroy();
        } catch (err) {
          console.error(err);
        }
      };
    }
  }, [loaded]);

  useEffect(() => {
    if (rankData && rankData.data) {
      const { rank } = rankData.data;
      gameRef.current?.events.emit('update-rank', { rank });
    }
  }, [rankData]);

  useEffect(() => {
    gameRef.current?.events.emit('update-app-version', appVersion);
  }, [appVersion]);

  useEffect(() => {
    if (isEnded) gameRef.current?.events.emit('game-ended');
  }, [isEnded]);

  useEffect(() => {
    gameRef.current?.events.emit('update-eth-balance', { address, ETHBalance });
  }, [address, ETHBalance]);

  useEffect(() => {
    gameRef.current?.events.emit('update-gas-mint', { gas: estimatedGas?.game?.buyGangster });
  }, [estimatedGas?.game?.buyGangster]);

  useEffect(() => {
    gameRef.current?.events.emit('update-gas-buy-goon', { gas: estimatedGas?.game?.buyAsset });
    gameRef.current?.events.emit('update-gas-upgrade-safehouse', { gas: estimatedGas?.game?.buyAsset });
  }, [estimatedGas?.game?.buyAsset]);

  useEffect(() => {
    gameRef.current?.events.emit('update-balances', { ETHBalance, tokenBalance });
  }, [tokenBalance, ETHBalance]);

  useEffect(() => {
    gameRef.current?.events.emit('update-balances-for-withdraw', {
      NFTBalance: numberOfMachines,
      ETHBalance,
      tokenBalance,
    });
  }, [numberOfMachines, ETHBalance, tokenBalance]);

  useEffect(() => {
    gameRef.current?.events.emit('update-xtoken-balance', { balance: xTokenBalance });
  }, [xTokenBalance]);

  useEffect(() => {
    gameRef.current?.events.emit('update-profile', {
      username,
      socials,
      address,
      avatarURL: avatarURL_big ?? avatarURL,
    });
  }, [username, socials, address, avatarURL, avatarURL_big]);

  useEffect(() => {
    if (isLeaderboardModalOpen) {
      const { name } = activeSeason || {};
      gameRef.current?.events.emit('update-season', {
        name,
        prizePoolEth,
        prizePoolToken,
        blastGoldReward,
        xUPool: xUReward,
        isEnded,
        rewardPercentNextPayout: midSeasonSnapshotTaken
          ? 1 - prizePoolConfig.midSeasonPrizePoolPercentage
          : prizePoolConfig.midSeasonPrizePoolPercentage,
      });
    }
  }, [
    isLeaderboardModalOpen,
    activeSeason?.name,
    prizePoolEth,
    prizePoolToken,
    blastGoldReward,
    isEnded,
    midSeasonSnapshotTaken,
    prizePoolConfig.midSeasonPrizePoolPercentage,
  ]);

  // useEffect(() => {
  //   if (isLeaderboardModalOpen) gameRef.current?.events.emit('update-leaderboard', leaderboardData?.data || []);
  // }, [isLeaderboardModalOpen, leaderboardData?.data]);

  useEffect(() => {
    const calculateNetworthInterval = setInterval(() => {
      calculateNetworthRef?.current?.();
    }, 5 * 60 * 1000);

    return () => clearInterval(calculateNetworthInterval);
  }, []);

  useEffect(() => {
    calculateNetworthRef?.current?.();
  }, [networth]);

  useEffect(() => {
    gameRef.current?.events.emit('update-starter-pack-countdown', {
      countdownString: starterPackCountdown.countdownString,
      isEnded: starterPackCountdown.isEnded,
    });
  }, [starterPackCountdown.countdownString, starterPackCountdown.isEnded]);

  useEffect(() => {
    gameRef.current.events.emit('update-starter-pack-config', activeSeason?.starterPackConfig);
  }, [activeSeason?.starterPackConfig]);

  useEffect(() => {
    gameRef.current.events.emit('update-referral-config', activeSeason?.referralConfig);
  }, [activeSeason?.referralConfig]);

  useEffect(() => {
    gameRef.current?.events.emit('update-referral-code', { referralCode });
  }, [referralCode]);

  useEffect(() => {
    if (inviteCode) gameRef.current.events.emit('update-invite-code', { code: inviteCode });
  }, [inviteCode]);

  useEffect(() => {
    gameRef.current?.events.emit('update-referral-data', {
      referralTotalReward,
      referralTotalDiscount,
      referralProgramType,
      tokenPrice,
      ethPriceInUsd,
      tweetTemplate: templates.twitterShareReferralCode || '',
    });
  }, [
    referralTotalReward,
    referralTotalDiscount,
    referralProgramType,
    tokenPrice,
    ethPriceInUsd,
    templates.twitterShareReferralCode,
  ]);

  useEffect(() => {
    gameRef.current?.events.emit('update-u-point-reward', { uPointReward });
  }, [uPointReward]);

  useEffect(() => {
    gameRef.current?.events.emit('update-market-data', { tokenPrice, ethPriceInUsd });
  }, [tokenPrice, ethPriceInUsd]);

  useEffect(() => {
    gameRef.current?.events.emit('update-prize-pool-info', {
      prizePoolConfig,
      xUPool: xUReward,
      blastGoldReward,
    });
  }, [prizePoolConfig, xUReward, blastGoldReward]);

  useEffect(() => {
    gameRef.current?.events.emit('update-season-countdown', {
      countdownString,
      midSeasonCountdownString,
      startTimeString,
      started,
    });
  }, [countdownString, started, startTimeString]);

  useEffect(() => {
    const { countdownString, snapshotString } = blastGoldSnapshotCountdown;
    gameRef.current?.events.emit('update-blast-gold-snapshot-countdown', { countdownString, snapshotString });
  }, [blastGoldSnapshotCountdown.countdownString, blastGoldSnapshotCountdown.snapshotString]);

  useEffect(() => {
    updateNextAuctionPrize.current?.();
  }, [nextAuctionPrizePool]);

  useEffect(() => {
    gameRef.current?.events.emit('update-active-status', { active: gamePlay?.active });
  }, [gamePlay?.active]);

  useEffect(() => {
    gameRef.current?.events.emit('game-sound-changed', { sound });
  }, [sound]);

  useEffect(() => {
    if (profile?.code) {
      gameRef.current.events.emit('update-deposit-code', profile?.code);
    }
  }, [profile?.code]);

  useEffect(() => {
    if (activeSeasonEstimatedEndTime && activeSeason?.claimGapInSeconds && gamePlay?.lastClaimTime) {
      gameRef.current?.events.emit('update-claim-time', {
        claimGapInSeconds: activeSeason?.claimGapInSeconds,
        lastClaimTime: gamePlay?.lastClaimTime?.toDate().getTime(),
        active: gamePlay.active,
      });

      const endUnixTime = activeSeason.estimatedEndTime.toDate().getTime();
      const nextClaimTime = gamePlay?.lastClaimTime.toDate().getTime() + activeSeason.claimGapInSeconds * 1000;
      const now = Date.now();
      const claimable = now > nextClaimTime && now < endUnixTime;
      gameRef.current?.events.emit('update-claimable-status', { claimable, active: gamePlay.active });
    }
  }, [activeSeasonEstimatedEndTime, activeSeason?.claimGapInSeconds, gamePlay?.lastClaimTime]);

  useEffect(() => {
    calculateClaimableRewardRef.current?.();
  }, [gamePlay?.startXTokenCountingTime, gamePlay?.pendingXToken, dailyXToken]);

  useEffect(() => {
    getNFTBalance(address)
      .then((balance) => {
        gameRef.current?.events.emit('update-wallet-nft-balance', { balance, numberOfMachines });
      })
      .catch((err) => {
        console.error(err);
        Sentry.captureException(err);
      });
  }, [address, numberOfMachines]);

  useEffect(() => {
    updateBuildingRef.current?.();
  }, [
    numberOfBuildings,
    building,
    numberOfMachines,
    listeningBuilding,
    activeSeason?.startTime,
    activeSeason?.buildingSold,
  ]);

  useEffect(() => {
    updateThugRef.current?.();
  }, [numberOfThugs, thug, listeningThug, activeSeason?.startTime, activeSeason?.thugSold]);

  useEffect(() => {
    updatePoorTokenBalanceRef.current?.();
  }, [gamePlay?.poorTokenBalance, gamePlay?.startPoorTokenCountingTime]);

  useEffect(() => {
    updateWorkerRef.current?.();
  }, [
    numberOfWorkers,
    tokenBalance,
    worker,
    activeSeason?.warConfig?.tokenRewardPerEarner,
    activeSeason?.workerSold,
    activeSeason?.startTime,
    listeningWorker,
  ]);

  useEffect(() => {
    updateMachineRef.current?.();
  }, [
    numberOfMachines,
    tokenBalance,
    machine,
    tokenPrice,
    isWhitelisted,
    whitelistAmountMinted,
    gamePlay?.machine,
    activeSeason?.startTime,
    activeSeason?.machineSold,
    listeningMachine,
  ]);

  useEffect(() => {
    gameRef.current?.events.emit('update-workers-machines', { numberOfWorkers, numberOfMachines, numberOfThugs });
  }, [numberOfWorkers, numberOfMachines, numberOfThugs]);

  useEffect(() => {
    const machineValue = numberOfMachines * parseFloat(nftPrice);
    gameRef.current?.events?.emit('update-portfolio', {
      address,
      ETHBalance,
      tokenBalance,
      tokenPrice,
      numberOfMachines,
      machineValue,
      blastPointReward,
    });
  }, [address, tokenPrice, nftPrice, tokenBalance, numberOfMachines, ETHBalance, blastPointReward]);

  useEffect(() => {
    if (rankData?.data) {
      const { rank, totalPlayers } = rankData.data;
      gameRef.current?.events.emit('update-statistic', {
        rank,
        totalPlayers,
        numberOfWorkers,
        numberOfMachines,
        numberOfBuildings,
        numberOfThugs,
        raidPoint,
        numberOfPistols,
        numberOfShields,
      });
    }
  }, [
    rankData,
    numberOfWorkers,
    numberOfThugs,
    numberOfMachines,
    numberOfBuildings,
    raidPoint,
    numberOfPistols,
    numberOfShields,
  ]);

  useEffect(() => {
    if (userHasInteractive) {
      gameRef.current?.events.emit('music-on');
    }
  }, [userHasInteractive]);

  useEffect(() => {
    gameRef.current?.events.emit('update-game-play', {
      numberOfMachines,
      numberOfAvailableMachines,
      numberOfWorkers,
      numberOfBuildings,
      totalPistolBonus: numberOfPistols * pistol.attackPoint,
      totalShieldBonus: numberOfShields * shield.defencePoint,
      ...warDeployment,
      ...warConfig,
      warNext: warState?.next || 1,
      latestWarChain,
    });
  }, [
    numberOfMachines,
    numberOfAvailableMachines,
    numberOfWorkers,
    numberOfBuildings,
    numberOfPistols,
    numberOfShields,
    pistol?.attackPoint,
    shield?.defencePoint,
    warDeployment,
    warConfig,
    latestWarChain,
    warState,
  ]);

  useEffect(() => {
    gameRef.current?.events.emit('update-war-upgrades', {
      numberOfPistols,
      numberOfShields,
      balance: tokenBalance,
      pistolPrice: pistol.price,
      pistolMaxPerBatch: pistol.maxPerBatch,
      pistolAttackPoint: pistol.attackPoint,
      shieldPrice: shield.price,
      shieldMaxPerBatch: shield.maxPerBatch,
      shieldDefencePoint: shield.defencePoint,
    });
  }, [numberOfPistols, numberOfShields, tokenBalance, pistol, shield]);

  useEffect(() => {
    if (warConfig) {
      const { tokenRewardPerEarner, earningStealPercent, machinePercentLost, stolenNetworthPercent, sizeAdjustment } =
        warConfig;
      gameRef.current?.events.emit('update-war-config', {
        tokenRewardPerEarner,
        earningStealPercent,
        machinePercentLost,
        stolenNetworthPercent,
        sizeAdjustment,
      });
    }
  }, [warConfig]);

  useEffect(() => {
    updateSpinRewardRef?.current?.();
  }, [spinRewards, networth, tokenReputationRewardMutiplier, costReputationMultiplier, basePrice]);

  useEffect(() => {
    if (gamePlay) {
      gameRef.current?.events.emit('update-badge-number', {
        numberOfSpins: gamePlay.numberOfSpins,
      });
    }
  }, [gamePlay?.numberOfSpins]);

  useEffect(() => {
    if (gamePlay) {
      gameRef.current?.events.emit('update-unread-messages', {
        numberOfUnreadMessages: gamePlay.numberOfUnreadMessages,
      });
    }
  }, [gamePlay?.numberOfUnreadMessages]);

  useEffect(() => {
    if (activeSeason?.spinConfig) {
      gameRef.current?.events.emit('update-spin-config', {
        spinIncrementStep: activeSeason?.spinConfig?.spinIncrementStep,
        maxSpin: activeSeason?.spinConfig?.maxSpin,
      });
    }
  }, [activeSeason?.spinConfig]);

  useEffect(() => {
    if (activeSeason?.status !== 'open') {
      gameRef.current?.events.emit('game-ended');
      gameRef.current?.events.emit('stop-animation');
    }
  }, [activeSeason?.status]);

  useEffect(() => {
    gameRef.current?.events.emit('update-twitter-verified', { twitterVerified: !!twitterVerified });
  }, [twitterVerified]);

  useEffect(() => {
    gameRef.current?.events.emit('update-machine-numbers', {
      numberOfMachines: gamePlay?.numberOfMachines,
      gangwarMinNumberOfMachines: activeSeason?.warConfig?.gangwarMinNumberOfMachines,
    });
  }, [gamePlay?.numberOfMachines, activeSeason?.gangwarMinNumberOfMachines]);

  useEffect(() => {
    if (activeSeason?.auctionState?.activeAuctionId) {
      gameRef.current?.events?.emit('auction-changed');
    }
  }, [activeSeason?.auctionState?.activeAuctionId]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={`${windowHeight}px`}
      onClick={() => {
        !userHasInteractive && setUserHasInteracted(true);
      }}>
      <Box
        position="relative"
        id="game-container"
        width="100vw"
        height={`${windowHeight}px`}
        overflow="hidden"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={
          showBg
            ? {
                backgroundImage: {
                  xs: 'url(/images/bg-login-vertical.webp)',
                  sm: 'url(/images/bg-login-5x4.webp)',
                  md: 'url(/images/bg-login.webp)',
                },
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                '& canvas': { position: 'absolute' },
                '&::after': { content: '""', backgroundColor: 'rgba(0, 0, 0, 0.4)', width: '100%', height: '100%' },
              }
            : {}
        }>
        {showBg && (
          <>
            <Box position="absolute" top={0} left={0} width="100%" height="100%" zIndex={10} display="flex">
              <Box mt="50vh" p={2} width="100%" display="flex" flexDirection="column" alignItems="center" gap={2}>
                <Box
                  width="100px"
                  mb="14vh"
                  sx={{
                    '& img': {
                      width: '100%',
                      animationName: 'spin',
                      animationDuration: '5000ms',
                      animationIterationCount: 'infinite',
                      animationTimingFunction: 'linear',
                    },
                  }}>
                  <img src="/images/icons/loading.png" />
                </Box>
                <Box height={48}>
                  {userCanReload && (
                    <Box
                      alignSelf="center"
                      position="relative"
                      sx={{ cursor: 'pointer', userSelect: 'none' }}
                      onMouseDown={() => setMouseDown(true)}
                      onMouseUp={() => setMouseDown(false)}
                      onClick={() => {
                        logAnalyticsEvent('user_reload', {
                          loading_duration: (Date.now() - startLoadingTime) / 1000,
                        });
                        logout();
                        // window.location.reload();
                      }}>
                      <Box width="120px" sx={{ '& img': { width: '100%' } }}>
                        <img
                          draggable={false}
                          src={mouseDown ? '/images/button-blue-pressed.png' : '/images/button-blue.png'}
                          alt="button"
                        />
                      </Box>
                      <Box position="absolute" top="45%" left="50%" sx={{ transform: 'translate(-50%, -50%)' }}>
                        <Typography
                          fontSize={20}
                          fontWeight={700}
                          color="white"
                          fontFamily="WixMadeforDisplayBold"
                          sx={{ userSelect: 'none' }}>
                          Retry
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Box height="100px"></Box>
    </Box>
  );
};

export default Game;
